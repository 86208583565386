import { GET_USER, CREATE_USER, UPDATE_USER, DELETE_USER, GET_USERS, GET_AUTHORS, GET_DESIGNERS, GET_REVIEWERS, GET_ROLES, USER_ERRORS, EMPTY_ERRORS, USER_CREATED, SET_USERS_FILTER } from "./types";

import axios from 'axios';
import { base_url, check_response } from "../utils";

// get all users
export const getUsers = () => async dispatch => {

    axios.post(`${base_url}users`,
        {},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_USERS,
                    payload: response.data.users
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });

}

// get a user
export const getUser = (id) => async dispatch => {

    axios.post(`${base_url}users/get-user`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_USER,
                    payload: response.data.user
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// create a user
export const createUser = (user) => async dispatch => {

    axios.post(`${base_url}users/add-user`,
        user,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CREATE_USER,
                    payload: response.data.user
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });

}

// update a user
export const updateUser = (user) => async dispatch => {

    axios.post(`${base_url}users/update-user`,
        user,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: UPDATE_USER,
                    payload: response.data.user
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// get all users
export const getRoles = () => async dispatch => {
    axios.get(`${base_url}users/get-user-roles`,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_ROLES,
                    payload: response.data.roles
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// Empty Errors
export const emptyErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_ERRORS,
        payload: null
    })
}

// user Created
export const userCreated = () => async dispatch => {
    dispatch({
        type: USER_CREATED,
        payload: null
    })
}

export const setUsersFilter = (filter) => async dispatch => {
    dispatch({
        type: SET_USERS_FILTER,
        payload: filter
    });
}