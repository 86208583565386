import {
    SET_PAGE_HEADING,
    SHOW_BOOK_HEADING,
    SERVER_ERROR,
    ALERT_MESSAGE,
    ALERT_MESSAGE_EMPTY,
    ERRORS_GET,
    ERRORS_EMPTY,
    FILE_NAME
} from "../actions/types";

const initialState = {
    pageHeading: '',
    bookHead: null,
    response: null,
    errors: null,
    server_error: null,
    fileName: null
};
export default (state = initialState, { type, payload }) => {
    console.log(type, payload);
    switch (type) {
        case SET_PAGE_HEADING:
            return {
                ...state,
                pageHeading: payload
            }
        case SHOW_BOOK_HEADING:
            return {
                ...state,
                bookHead: payload
            }
        case ALERT_MESSAGE:
            return {
                ...state,
                response: payload
            }
        case ALERT_MESSAGE_EMPTY:
            return {
                ...state,
                response: null
            }
        case ERRORS_GET:
            return {
                ...state,
                errors: payload
            }
        case ERRORS_EMPTY:
            return {
                ...state,
                errors: [],
                response: null
            }
        case SERVER_ERROR:
            return {
                ...state,
                server_error: payload
            }
        case FILE_NAME:
            return {
                ...state,
                fileName: payload
            }
        default:
            return state;
    }
}