import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { setPageHeading } from "../../actions/globalActions";
import ContentSection from "../users/ContentSection"
import HeaderSection from "../users/HeaderSection"

const Users = () => {
    const dispatch = useDispatch();
    let history = useHistory();
    const profile = useSelector(state => state.users.profile);

    useEffect(() => {
        dispatch(setPageHeading('Users'));
    });

    useEffect(() => {
        if(!(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator')) {
            history.push('/');
        }
    }, [profile]);

    return (
        <>
            <HeaderSection />
            <ContentSection />
        </>
    );
}

export default Users;