import {
    SET_PAGE_HEADING,
    SHOW_BOOK_HEADING,
    ALERT_MESSAGE_EMPTY,
    ERRORS_EMPTY
} from "./types";

// set page heading
export const setPageHeading = (heading) => async dispatch => {
    dispatch({
        type: SET_PAGE_HEADING,
        payload: heading
    });
}

// show book heading
export const showBookHead = (heading) => async dispatch => {
    dispatch({
        type: SHOW_BOOK_HEADING,
        payload: heading
    });
}

// Empty Alert Message
export const emptyAlertMessage = () => async dispatch => {
    dispatch({
        type: ALERT_MESSAGE_EMPTY,
        payload: null
    });
}

// Empty Errors
export const emptyErrors = () => async dispatch => {
    dispatch({
        type: ERRORS_EMPTY,
        payload: null
    });
}