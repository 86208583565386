import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../actions/authActions";

const Pagetop = () => {
    const dispatch = useDispatch();
    const logOut = () => {
        dispatch(logoutUser());
    }
    const pageHeading = useSelector(state => state.globals.pageHeading);
    return (
        <div id="page_top" className="section-body top_dark">
            <div className="container-fluid">
                <div className="page-header">
                    <div className="left">
                        <a href="javascript:void(0)" className="icon menu_toggle mr-3"><i className="fa  fa-align-left" /></a>
                        <h1 className="page-title">{pageHeading}</h1>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default Pagetop;