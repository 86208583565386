import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../actions/categoryActions";
import CategoryItem from "./CategoryItem";

const CategoriesList = () => {
    const dispatch = useDispatch();
    const categories = useSelector(state => state.categories.categories);

    useEffect(() => {
        dispatch(getCategories());
    }, []);

    return (
        <div className="col-12">
            <div className="card">
                <div className="table-responsive">
                    <table className="table table-hover table-striped table-vcenter mb-0 text-nowrap">
                        <thead>
                            {/* <tr>
                                {comment && <th colSpan={6}>{book.bookName}</th>}
                                <th colSpan={3}><Link className="nav-link" data-toggle="tab" to={`${match.url}/add-chapter`}>Add Chapter</Link></th>
                            </tr> */}

                            <tr>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            {categories && categories.map(category =>
                                <CategoryItem key={category.id} category={category} />
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
}

export default CategoriesList;