import {
    GET_PROJECT,
    CREATE_PROJECT,
    UPDATE_PROJECT,
    DELETE_PROJECT,
    GET_PROJECTS,
    PROJECT_CREATED,
    PROJECT_ERRORS,
    EMPTY_PROJECT_ERRORS,
    GET_PROJECT_GRAPH,
    SET_PROJECT_FILTER
} from "./types";

import axios from 'axios';
import { base_url, check_response } from "../utils";

//get all projects
export const getProjects = (filters) => async dispatch => {

    axios.post(`${base_url}books`,
        filters,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_PROJECTS,
                    payload: response.data.books
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// get project graph
export const getGraph = (id) => async dispatch => {

    axios.post(`${base_url}books/get-book-graph-data`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_PROJECT_GRAPH,
                    payload: response.data.book
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// get a project
export const getProject = (id) => async dispatch => {

    axios.post(`${base_url}books/get-book`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_PROJECT,
                    payload: response.data.book
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// create a project
export const createProject = (project) => async dispatch => {
    axios.post(`${base_url}books/add-book`,
        project,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CREATE_PROJECT,
                    payload: response.data.book
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update a project
export const updateProject = (project) => async dispatch => {
    axios.post(`${base_url}books/update-book`,
        project,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
            dispatch({
                type: UPDATE_PROJECT,
                payload: response.data.book
            });
        } else {
            dispatch(check_response(response));
        }
        })
        .catch(err => {
                dispatch(check_response(err.response));
        });

}

// project Created
export const projectCreated = () => async dispatch => {
    dispatch({
        type: PROJECT_CREATED,
        payload: null
    });
}

// Emptiy Errors
export const emptyProjectErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_PROJECT_ERRORS,
        payload: null
    });
}

export const setProjectsFilter = (filter) => async dispatch => {
    dispatch({
        type: SET_PROJECT_FILTER,
        payload: filter
    });
}

export const setBooksCategory = (categoryId) => async dispatch => {

}