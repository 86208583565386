import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { commentCreated, createComment } from "../../actions/commentActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

const CommentsAdd = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.comments.isCreated);

    const [name, setName] = useState("");
    const [color, setColor] = useState("");

    useEffect(() => {
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
    }, []);

    useEffect(() => {
        if (isCreated) {
            dispatch(commentCreated());
            history.push(`/labels`);
        }
    }, [isCreated]);

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_comment = {
            labelName: name,
            labelColor: color
        };

        dispatch(createComment(new_comment));
    };

    return ( 
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitForm}>
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <input type="text" required className="form-control" placeholder="Name"
                                        value={name} onChange={(e) => setName(e.target.value)} />
                                        <p className="error">{errors && errors.labelName}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Color</label>
                                    <input type="color" required className="" placeholder="Color"
                                        value={color} onChange={(e) => setColor(e.target.value)} />
                                        <p className="error">{errors && errors.labelColor}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <button type="submit" className="btn btn-primary">Add</button>
                                <button type="button" onClick={() => history.goBack()} className="btn btn-default">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
     );
}
 
export default CommentsAdd;