import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router";
import { commentCreated, getComment, updateComment } from "../../actions/commentActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

const CommentsUpdate = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const { id } = useParams();
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.comments.isCreated);
    const comment = useSelector(state => state.comments.comment);

    const [name, setName] = useState("");
    const [color, setColor] = useState("");
    const [isDeleted, setIsDeleted] = useState("");

    useEffect(() => {
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getComment(id));
    }, []);

    useEffect(() => {
        if (isCreated) {
            dispatch(commentCreated());
            history.push(`/labels`);
        }
    }, [isCreated]);

    useEffect(() => {
        if (comment) {
            setName(comment.labelName);
            setColor(comment.labelColor);
            setIsDeleted(comment.isDeleted);
        }
    }, [comment])

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_comment = {
            id: id,
            labelName: name,
            labelColor: color,
            isDeleted: isDeleted
        };

        dispatch(updateComment(new_comment));
    };
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitForm}>
                        <div className="row clearfix">
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Name</label>
                                    <input type="text" required className="form-control" placeholder="Name"
                                        value={name} onChange={(e) => setName(e.target.value)} />
                                    <p className="error">{errors && errors.labelName}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Color</label>
                                    <input type="color" required className="" placeholder="Color"
                                        value={color} onChange={(e) => setColor(e.target.value)} />
                                    <p className="error">{errors && errors.labelColor}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Status</label>
                                    <select class="form-control show-tick" required
                                        value={isDeleted} onChange={(e) => setIsDeleted(e.target.value)}>
                                        <option value="">--Status--</option>
                                        <option value="0">Enabled</option>
                                        <option value="1">Disabled</option>
                                    </select>
                                    <p className="error">{errors.isDeleted}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <button type="submit" className="btn btn-primary">Update</button>
                                <button type="button" onClick={() => history.goBack()} className="btn btn-default">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CommentsUpdate;