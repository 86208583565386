import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { setUsersFilter } from "../../actions/userAction";

const HeaderSection = () => {
    let match = useRouteMatch();
    const dispatch = useDispatch();
    const userFilter = useSelector(state => state.users.filter);
    const setUserFilter = (filter) => {
        dispatch(setUsersFilter(filter));
    }
    // const [userFilter, setUserFilter] = useState();
    return (
        <>
            <div className="section-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="d-flex justify-content-between align-items-center">
                                <ul className="nav nav-tabs page-header-tab">
                                    <li className="nav-item">
                                        <Link className="nav-link active" data-toggle="tab" to={`${match.url}`}>Users</Link>
                                    </li>
                                    {/* <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Project-UpComing">UpComing</a></li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link" data-toggle="tab" to={`${match.url}/add-user`}>Add New User</Link>
                                    </li>
                                </ul>
                                <div className="d-md-flex">
                                <div className="input-group mr-2">
                                    <input
                                        type="button" onClick={() => setUserFilter('active')}
                                        className={userFilter == 'active' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                         value="Active Users" />

                                    <input type="button" onClick={() => setUserFilter('all')}
                                        className={userFilter == 'all' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                        value="All Users" />
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default HeaderSection;