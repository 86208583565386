import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageHeading } from "../../actions/globalActions";
import CategoriesContent from "../categories/CategoriesContent";
import CategoriesHead from "../categories/CategoriesHead";

const Categories = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageHeading('Book Categories'));
    })
    return ( 
        <>
            <CategoriesHead />
            <CategoriesContent />
        </>
     );
}
 
export default Categories;