import { useEffect, useRef, useState } from "react";
import NumberFormat from "react-number-format";
import ProgressBar from "@ramonak/react-progress-bar";
import { useDispatch, useSelector } from "react-redux";
import { emptyProfileErrors, getProfile, updateProfile, updateProfileImage } from "../../actions/profileActions";
import { server_url } from "../../utils";
import { setPageHeading } from "../../actions/globalActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import Alert from "./../elements/Alert";

const Profile = () => {
    const imageRef = useRef();
    const dispatch = useDispatch();
    const profile = useSelector(state => state.profile.profile);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.profile.isCreated);
    const progress = useSelector(state => state.profile.progress);

    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [phone, setPhone] = useState("");
    const [cnic, setCnic] = useState("");
    const [gender, setGender] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [image, setImage] = useState("");

    useEffect(() => {
        console.log('prog', progress);
    }, [progress]);

    useEffect(() => {
        dispatch(setPageHeading('Profile'));
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getProfile());
        console.log('pakistan...')
    }, []);

    useEffect(() => {
        if (profile) {
            setName(profile.name);
            setPhone(profile.phone);
            setCnic(profile.cnic);
            setGender(profile.gender);
            setUsername(profile.username);
            setEmail(profile.email);
        }
    }, [profile]);

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_user = {
            name,
            phone,
            cnic,
            email,
            gender,
            password
        };
        dispatch(updateProfile(new_user));
    };

    const updateImage = (e) => {
        if (e.files && e.files[0]) {
            setImage(e.files[0]);

            const data = new FormData()
            data.append('image', e.files[0], e.files[0].name);
            console.log('data', data);

            dispatch(updateProfileImage(data));
        }
    }

    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="tab-content">
                    <div className="section-body mt-4">
                        <div className="container-fluid">
                            <div className="row clearfix">
                                <div className="col-lg-2 col-md-12">

                                    <div className="card profile-pic">
                                        <img className="card-img-top" src={server_url + profile.image} alt={profile.name} />
                                        <div class="edit"><a onClick={() => imageRef.current.click()} href="javascript:void(0);"><i class="fa fa-edit fa-lg"></i></a></div>
                                        {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />}
                                        <input ref={imageRef} type="file" value={Image} hidden="true" className="form-control" placeholder="Name" required
                                            onChange={(e) => updateImage(e.target)} />
                                        <p className="error">{errors && errors.image}</p>

                                        {/* <button type="file" className="form-control" /> */}
                                        <div className="card-body p-2">
                                            <h5 className="card-title mb-1"><strong>{profile.name}</strong></h5>
                                            <p class="card-text mb-1">{profile.role}</p>
                                            <p class="card-text mb-1">{profile.employeeId}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-10 col-md-12">
                                    <div className="row">
                                        <div className="card">
                                            <div className="card-body">
                                                <form onSubmit={submitForm} >
                                                    <div className="row clearfix">
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Name</label>
                                                                <input type="text" className="form-control" placeholder="Name" required
                                                                    value={name} onChange={(e) => setName(e.target.value)} />
                                                                <p className="error">{errors && errors.name}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Username</label>
                                                                <input type="text" className="form-control" disabled placeholder="Username"
                                                                    value={username} />
                                                                <p className="error">{errors && errors.username}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-12">
                                                            <div className="form-group">
                                                                <label className="form-label">Email</label>
                                                                <input type="email" required className="form-control" placeholder="Enter Email"
                                                                    value={email} onChange={(e) => setEmail(e.target.value)} />
                                                                <p className="error">{errors && errors.email}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Phone #</label>
                                                                <input type="text" className="form-control" placeholder="Phone #"
                                                                    value={phone} onChange={(e) => setPhone(e.target.value)} />

                                                                <p className="error">{errors && errors.phone}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">CNIC</label>
                                                                <NumberFormat className="form-control" format="#####-#######-#"
                                                                    value={cnic} onChange={(e) => setCnic(e.target.value)} />
                                                                <p className="error">{errors && errors.cnic}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Select Gender</label>
                                                                <select class="form-control show-tick" required
                                                                    value={gender} onChange={(e) => setGender(e.target.value)}>
                                                                    <option value="">--Gender--</option>
                                                                    <option value="male">Male</option>
                                                                    <option value="female">Female</option>
                                                                </select>
                                                                <p className="error">{errors && errors.gender}</p>
                                                            </div>
                                                        </div>
                                                        <div className="col-lg-6 col-md-6">
                                                            <div className="form-group">
                                                                <label className="form-label">Password</label>
                                                                <input type="text" className="form-control" placeholder="Password"
                                                                    value={password} onChange={(e) => setPassword(e.target.value)} />
                                                                <p className="error">{errors && errors.password}</p>
                                                            </div>
                                                        </div>
                                                        <Alert />
                                                    </div>
                                                    <div className="card-footer text-right">
                                                        <button type="submit" className="btn btn-primary">Update Profile</button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Profile;