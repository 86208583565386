import { GET_COMMENT, CREATE_COMMENT, UPDATE_COMMENT, DELETE_COMMENT, GET_COMMENTS, COMMENT_ERRORS, EMPTY_COMMENT_ERRORS, COMMENT_CREATED } from "./types";

import axios from 'axios';
import { base_url, check_response } from "../utils";

// get all comments
export const getComments = () => async dispatch => {

    axios.post(`${base_url}labels`,
        {},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_COMMENTS,
                    payload: response.data.labels
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}
// get a comment
export const getComment = (id) => async dispatch => {

    axios.post(`${base_url}labels/get-label`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_COMMENT,
                    payload: response.data.label
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// create a comment
export const createComment = (comment) => async dispatch => {

    axios.post(`${base_url}labels/add-label`,
        comment,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CREATE_COMMENT,
                    payload: response.data.label
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update a comment
export const updateComment = (comment) => async dispatch => {

    axios.post(`${base_url}labels/update-label`,
        comment,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: UPDATE_COMMENT,
                    payload: response.data.label
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// Empty Errors
export const emptyCommentErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_COMMENT_ERRORS,
        payload: null
    })
}

// comment Created
export const commentCreated = () => async dispatch => {
    dispatch({
        type: COMMENT_CREATED,
        payload: null
    })
}