
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { getGraph } from '../../actions/projectActions';

const BookGraph = () => {
    
    const graph = useSelector(state => state.books.graph);
    const bookName = useSelector(state => state.books.bookName);
    const { id } = useParams();
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getGraph(id));
    }, []);

    const options = {
        chart: {
            zoomType: 'x'
        },
        title: {
            text: bookName
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Book Progress'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 5
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },
    
        series: [{
            type: 'area',
            name: 'Book Progress',
            data: graph
        }]
    }

    return ( 
        
        <div className="col-lg-12">
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    </div>
     );
}
 
export default BookGraph;