import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from 'react-select';
import { showBookHead } from "../../actions/globalActions";
import { createProject, emptyProjectErrors, getProject, projectCreated, updateProject } from "../../actions/projectActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

import { getUsers } from "../../actions/userAction";

const UpdateBook = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector(state => state.users.users);
    const book = useSelector((state) => state.books.book);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.books.isCreated);
    const categories = useSelector(state => state.categories.categories);
    const { id } = useParams();

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [designer, setDesigner] = useState("");
    const [reviewers, setReviewers] = useState("");
    const [projectCoordinator, setProjectCoordinator] = useState("");
    const [academicHead, setAcademicHead] = useState("");
    const [phase, setPhase] = useState("");
    const [isDeleted, setIsDeleted] = useState("");
    const [options, setOptions] = useState([]);
    const [category, setCategory] = useState("");

    useEffect(() => {
        loadBook();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getUsers());
        dispatch(showBookHead(true));
    }, []);

    useEffect(() => {
        if (book) {
            const reviewerData = [];
            setName(book.bookName);
            setDescription(book.bookDescription);
            setDesigner(book.designers && book.designers[0].id);
            book.reviewers.map(reviwer => {
                reviewerData.push({ value: reviwer.id, label: reviwer.reviewerName })
            });
            setReviewers(reviewerData);
            setAcademicHead(book.academicHead);
            setProjectCoordinator(book.projectCoordinator);
            setIsDeleted(book.isDeleted);
            setCategory(book.categoryId);
        }
    }, [book]);

    useEffect(() => {
        if (isCreated) {
            dispatch(projectCreated());
            history.push("/books");
        }
    }, [isCreated]);

    useEffect(() => {
        const opts = [];
        users && users.map(user => {
            if (user.role === "reviewer") {
                opts.push({ value: user.id, label: user.name });
            }
        });
        setOptions(opts);
    }, [users]);

    const loadBook = () => {
        dispatch(getProject(id));
    };

    const onReviewSelect = (selectedReviews) => {
        setReviewers(selectedReviews);
    }

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const reviwersData = [];
        reviewers.map((reviwer) => {
            reviwersData.push(reviwer.value);
        });
        const designersData = [];
        designersData.push(designer);

        const new_book = {
            id: id,
            bookName: name,
            bookDescription: description,
            designers: designersData,
            reviewers: reviwersData,
            projectCoordinator: projectCoordinator,
            academicHead: academicHead,
            isDeleted: isDeleted,
            categoryId: category
        };
        dispatch(updateProject(new_book));
    };
    return (
        <div class="tab-pane fade show active" id="Project-add" role="tabpanel">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={submitForm}>
                                <div className="row clearfix">
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Name</label>
                                            <input type="text" required className="form-control" placeholder="Book Name"
                                                value={name} onChange={(e) => setName(e.target.value)} />
                                            <p className="error">{errors && errors.bookName}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Academic Head</label>
                                            <select required class="form-control show-tick" 
                                                value={academicHead} onChange={(e) => setAcademicHead(e.target.value)}>
                                                <option value="">--Academic Head--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "academicHead") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors && errors.academicHead}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Designer</label>
                                            <select class="form-control show-tick"
                                                value={designer} onChange={(e) => setDesigner(e.target.value)} >
                                                <option value="">--Designer--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "designer") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors && errors.designers}</p>
                                        </div>
                                    </div>
                                    {localStorage.role == "superAdmin" && <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Coordinator</label>
                                            <select class="form-control show-tick" required
                                                value={projectCoordinator} onChange={(e) => setProjectCoordinator(e.target.value)}>
                                                <option value="">--Select Coordinator--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "projectCoordinator") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors.projectCoordinator}</p>
                                        </div>
                                    </div>}
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Reviewer</label>
                                            <Select value={reviewers} isMulti={true}
                                                options={options} onChange={onReviewSelect} />
                                            <p className="error">{errors && errors.reviewers}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Category</label>
                                            <select class="form-control show-tick" required
                                                value={category} onChange={(e) => setCategory(e.target.value)}>
                                                <option value="">--Book Category--</option>
                                                {categories.map(category =>
                                                    <option value={category.id}>{category.categoryName}</option>)}
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Status</label>
                                            <select required class="form-control show-tick" 
                                                value={isDeleted} onChange={(e) => setIsDeleted(e.target.value)}>
                                                <option value="">--Book Status--</option>
                                                <option value="0">Enabled</option>
                                                <option value="1">Disabled</option>
                                            </select>
                                            <p className="error">{errors && errors.isDeleted}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Description</label>
                                            <textarea required type="text" className="form-control" rows={4} placeholder="Book Description"
                                                value={description} onChange={(e) => setDescription(e.target.value)} />
                                            <p className="error">{errors && errors.bookDescription}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <input type="file" className="dropify" />
                                    </div> */}
                                    <div className="col-lg-12 mt-3">
                                        <button type="submit" className="btn btn-primary">Update</button>
                                        <button type="button" onClick={() => history.goBack()} className="btn btn-default float-right">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UpdateBook;