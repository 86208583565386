import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { logoutUser } from "../../actions/authActions";
const Sidebar = () => {
    const dispatch = useDispatch();
    const logOut = () => {
        dispatch(logoutUser());
    }
    return (
        <div>
            <div id="left-sidebar" className="sidebar" style={{ 'padding': '15px 15px 20px 20px', 'width': '150px' }}>
                <h5 className="brand-name"><span style={{ 'color': '#dc9f2e' }}>EES</span>
                    <a href="javascript:void(0)" className="icon menu_toggle left-sidebar-menu-toggle float-right"><i className="fa  fa-align-left" /></a>
                </h5>
                <nav id="left-sidebar-nav" className="sidebar-nav">
                    <ul className="metismenu">
                        <li className="g_heading">...</li>
                        <li><Link to="/"><i className="fa fa-dashboard" /><span>Dashboard</span></Link></li>
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <li><Link to="/users"><i className="fa fa-user" /><span>Users</span></Link></li>}
                        <li><Link to="/books"><i className="fa fa-list-ol" /><span>Books</span></Link></li>
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <li><Link to="/categories"><i className="fa fa-tag" /><span>Categories</span></Link></li>}
                        <li><Link to="/profile"><i className="fa fa-address-book" /><span>Profile</span></Link></li>
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <li><Link to="/labels"><i className="fa fa-tag" /><span>Labels</span></Link></li>}
                        
                        <li><a onClick={() => {logOut()}} href="javascript:void(0);"><i className="fa fa-sign-out" /><span>Logout</span></a></li>
                    
                    </ul>
                </nav>
            </div>
        </div>
    );
}

export default Sidebar;