import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { chapterCreated, getChapter, updateChapter } from "../../actions/chapterActions";
import { getUsers } from "../../actions/userAction";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

const UpdateChapter = ({ bookIdd }) => {
    let history = useHistory();
    let match = useRouteMatch();
    const dispatch = useDispatch();
    const { chapId } = useParams();

    const users = useSelector(state => state.users.users);
    const chapter = useSelector(state => state.chapters.chapter);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.chapters.isCreated);

    const [description, setDescription] = useState("");
    const [exercise, setExercise] = useState("");
    const [author, setAuthor] = useState("");
    const [phase, setPhase] = useState("");
    const [title, setTitle] = useState("");
    const [isDeleted, setIsDeleted] = useState("");

    useEffect(() => {
        loadChapter();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getUsers())
    }, []);

    useEffect(() => {
        if (chapter) {
            setTitle(chapter.chapterName);
            setDescription(chapter.chapterDescription);
            setExercise(chapter.hasExercise);
            setAuthor(chapter.author);
            setPhase(chapter.phase);
            setIsDeleted(chapter.isDeleted);
        }
    }, [chapter]);

    const loadChapter = () => {
        dispatch(getChapter(chapId));
    };

    useEffect(() => {
        if (isCreated) {
            dispatch(chapterCreated());
            history.push(`/books/${bookIdd}`);
        }
    }, [isCreated]);

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_chapter = {
            chapterName: title,
            chapterDescription: description,
            hasExercise: exercise,
            author: author,
            id: chapId,
            isDeleted: isDeleted
        };

        dispatch(updateChapter(new_chapter));
    };
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitForm}>
                        <div className="row clearfix">
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Name</label>
                                    <input type="text" required className="form-control" placeholder="Chapter Name"
                                        value={title} onChange={(e) => setTitle(e.target.value)} />
                                    <p className="error">{errors && errors.chapterName}</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <input type="number" required className="form-control" placeholder="Chapter No."
                                        value={chapterNo} onChange={(e) => setChapterNo(e.target.value)} />
                                </div>
                            </div>*/}
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Author</label>
                                    <select class="form-control show-tick" required
                                        value={author} onChange={(e) => setAuthor(e.target.value)}>
                                        <option value="">--Author--</option>
                                        {users && users.map(user => {
                                            if (user.role == "author") {
                                                return <option value={user.id}>{user.name}</option>
                                            }
                                        })}
                                    </select>
                                    <p className="error">{errors && errors.author}</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <input type="text" required className="form-control" placeholder="Enter Password"
                                        value={password} onChange={(e) => setPassword(e.target.value)} />
                                </div>
                            </div> */}
                            {/* <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <input type="email" required className="form-control" placeholder="Enter Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                    </div> */}
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Contain Exercise?</label>
                                    <select class="form-control show-tick" required
                                        value={exercise} onChange={(e) => setExercise(e.target.value)}>
                                        <option value="">--Contain Exercise--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <p className="error">{errors && errors.hasExercise}</p>
                                </div>
                            </div>
                            <div className="col-lg-6 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Status</label>
                                    <select class="form-control show-tick" required
                                        value={isDeleted} onChange={(e) => setIsDeleted(e.target.value)}>
                                        <option value="">--Chapter Status--</option>
                                        <option value="0">Enabled</option>
                                        <option value="1">Disabled</option>
                                    </select>
                                    <p className="error">{errors && errors.isDeleted}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Description</label>
                                    <textarea type="text" className="form-control" rows={4} placeholder="Chapter Description"
                                        value={description} onChange={(e) => setDescription(e.target.value)} />
                                    <p className="error">{errors && errors.description}</p>
                                </div>
                            </div>
                            {/* <div className="col-lg-12">
                                        <input type="file" className="dropify" />
                                    </div> */}
                            <div className="col-lg-12 mt-3">
                                <button type="submit" className="btn btn-primary">Update</button>
                                <button type="button" onClick={() => history.goBack()} className="btn btn-default float-right">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default UpdateChapter;