import {
    GET_CHAPTER,
    CREATE_CHAPTER,
    UPDATE_CHAPTER,
    DELETE_CHAPTER,
    GET_CHAPTERS,
    CHAPTER_ERRORS,
    EMPTY_CHAPTER_ERRORS,
    CHAPTER_CREATED,
    GET_CHAPTER_CONTENT,
    CONTENT_ERRORS,
    CONTENT_UPDATE,
    UPDATE_CHAPTER_PROGRESS,
    GET_PENDING_TASKS,
    GET_ADMIN_PENDING_TASKS,
    GET_PIE_CHART_DATA,
    GET_CHAPTER_COMMENTS,
    EMPTY_PENDING_TASKS,
    FILE_NAME
} from "./types";

import axios from 'axios';
import { base_url, check_response } from "../utils";

// get all chapters
export const getChapters = (bookId) => async dispatch => {

    axios.post(`${base_url}chapters`,
        { bookId: bookId },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTERS,
                    payload: response.data.chapters
                });
                dispatch({
                    type: GET_PIE_CHART_DATA,
                    payload: response.data.pieChartData
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// get a chapter
export const getChapter = (id) => async dispatch => {

    axios.post(`${base_url}chapters/get-chapter`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER,
                    payload: response.data.chapter
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// create a chapter
export const createChapter = (chapter) => async dispatch => {
    axios.post(`${base_url}chapters/add-chapter`,
        chapter,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CREATE_CHAPTER,
                    payload: response.data.chapter
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update a chapter
export const updateChapter = (chapter) => async dispatch => {
    axios.post(`${base_url}chapters/update-chapter`,
        chapter,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: UPDATE_CHAPTER,
                    payload: response.data.chapter
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// Empty Errors
export const emptyChapterErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_CHAPTER_ERRORS,
        payload: null
    })
}

// chapter Created
export const chapterCreated = () => async dispatch => {
    dispatch({
        type: CHAPTER_CREATED,
        payload: null
    })
}

// http://ees.itinitial.com/api/chapters/chapter-content

export const getChapterContent = (id) => async dispatch => {
    axios.post(`${base_url}chapters/chapter-content`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER_CONTENT,
                    payload: response.data.chapterContent
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update chapter content
export const updateChapterContent = (chapterContent, bookId) => async dispatch => {

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_CHAPTER_PROGRESS,
                    payload: percent
                });
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}chapters/add-chapter-content`,
        chapterContent, options)
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CONTENT_UPDATE,
                    payload: response.data.chapterContent
                });
                dispatch(getChapters(bookId));
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

export const addChapterFileAction = (chapterContent) => async dispatch => {
    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_CHAPTER_PROGRESS,
                    payload: percent
                });
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}chapters/add-chapter-file`,
        chapterContent, options)
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: FILE_NAME,
                    payload: response.data.chapterFile
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update chapter content
export const addChapterContentReview = (chapterContent, bookId) => async dispatch => {

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_CHAPTER_PROGRESS,
                    payload: percent
                });
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}chapters/add-chapter-content-review`,
        chapterContent, options)
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CONTENT_UPDATE,
                    payload: response.data.chapterContent
                });
                dispatch(getChapters(bookId));
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// add chapter design guidelines
export const addChapterDesignGuidlines = (chapterContent, bookId) => async dispatch => {

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_CHAPTER_PROGRESS,
                    payload: percent
                });
                dispatch(getChapters(bookId));
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}chapters/add-chapter-design-guidelines`,
        chapterContent, options)
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CONTENT_UPDATE,
                    payload: response.data.chapterContent
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

//http://ees.itinitial.com/api/chapters/move-chapter-to-lineart

export const moveChapterToLineart = (id, bookId) => async dispatch => {
    axios.post(`${base_url}chapters/move-chapter-to-lineart`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER_CONTENT,
                    payload: response.data.chapterContent
                });
                dispatch(getChapters(bookId));
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

//addchapterComment
export const addchapterComment = (commentData) => async dispatch => {
    axios.post(`${base_url}comments/add-comment`,
         commentData ,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER_COMMENTS,
                    payload: response.data.chapterComments
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}
//deleteComment
export const deleteCommentAction = (id) => async dispatch => {
    axios.post(`${base_url}comments/delete-comment`,
         {id} ,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER_COMMENTS,
                    payload: response.data.chapterComments
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

//getchapterComments
export const getchapterComments = (id) => async dispatch => {
    axios.post(`${base_url}comments`,
         {id} ,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CHAPTER_COMMENTS,
                    payload: response.data.chapterComments
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

//chapters/pending-chapters
export const getPendingTasks = () => async dispatch => {
    axios.post(`${base_url}chapters/pending-chapters`,
        { },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_PENDING_TASKS,
                    payload: response.data.pendingChapters
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

export const emptyPendingTasks = () => async dispatch => {
    dispatch({
        type: EMPTY_PENDING_TASKS,
        payload: []
    });
}
//http://dev.portal.api.ehya.com.pk/api/pending-tasks
//super admin pending Tasks
export const getAdminPendingTasks = () => async dispatch => {
    axios.post(`${base_url}pending-tasks`,
        { },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_ADMIN_PENDING_TASKS,
                    payload: response.data.pendingTasks
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}