import {
    GET_CATEGORY,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORIES,
    CATEGORY_ERRORS,
    EMPTY_CATEGORY_ERRORS,
    CATEGORY_CREATED,
} from "../actions/types";

const initialState = {
    categories: [],
    category: null,
    errors: [],
    isCreated: false
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_CATEGORIES:
            return {
                ...state,
                categories: payload
            }
        case CREATE_CATEGORY:
            return {
                ...state,
                categories: [payload, ...state.categories],
                isCreated: true
            };
        case GET_CATEGORY:
            return {
                ...state,
                category: payload,
            };
        case UPDATE_CATEGORY:
            return {
                ...state,
                categories: state.categories.map((category) =>
                    category.id == payload.id ? payload : category
                ),
                isCreated: true
            };
        case DELETE_CATEGORY:
            return {
                ...state,
                categories: state.categories.filter((category) => category.id != payload),
            };
            case CATEGORY_ERRORS:
              return {
                ...state,
                errors: payload
              }
            case EMPTY_CATEGORY_ERRORS:
              return {
                ...state,
                errors: []
              }
            case CATEGORY_CREATED:
              return {
                ...state,
                isCreated: false
              }
        default:
            return state;
    }
};