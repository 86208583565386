import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { setPageHeading } from "../../actions/globalActions";
import { getGraph, getProjects, setProjectsFilter } from "../../actions/projectActions";
import { server_url } from "../../utils";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getAdminPendingTasks, getPendingTasks, emptyPendingTasks } from "../../actions/chapterActions";


const Home = () => {
    const history = useHistory();
    const location = useLocation();
    const books = useSelector(state => state.books.books);
    const graph = useSelector(state => state.books.graph);
    const pendingTasks = useSelector(state => state.chapters.pendingTasks);
    const adminPendingTasks = useSelector(state => state.chapters.adminPendingTasks);
    const dispatch = useDispatch();
    const [stats, setStats] = useState({ completed: 0, total: 0, writing: 0, designing: 0 });
    const [bookName, setBookName] = useState();
    const [currentBook, setCurrentBook] = useState();
    const [adminPendingChapters, setAdminPendingChapters] = useState([]);
    const [selected, setSelected] = useState(0);

    useEffect(() => {
        dispatch(emptyPendingTasks());
        dispatch(getAdminPendingTasks());
        dispatch(setPageHeading('Dashboard'));
        dispatch(getProjects());
        dispatch(getPendingTasks());
    }, []);

    useEffect(() => {
        adminPendingTasks.length > 0 && setAdminPendingChapters(adminPendingTasks[0].pendingChapters);
    }, [adminPendingTasks]);


    useEffect(() => {
        const script = document.createElement("script");
        script.src = "/assets/js/datatable.js";
        script.async = true;
        document.body.appendChild(script);
        let total_t = 0, completed_t = 0, writing_t = 0, designing_t = 0, book_id = 0, flag = true;

        books && books.map(book => {
            if (book.isDeleted == 0) {
                if (flag && book.bookPhase != "complete") {
                    book_id = book.id;
                    setBookName(book.bookName);
                    flag = false;
                }
                ++total_t;
                if (book.bookPhase == "complete") ++completed_t
                else if (book.bookPhase == "writing") ++writing_t
                else if (book.bookPhase == "designing") ++designing_t
            }
        });
        setStats({ total: total_t, completed: completed_t, writing: writing_t, designing: designing_t });
        setCurrentBook(book_id);
        books && dispatch(getGraph(book_id));
    }, [books]);

    const updatePendingChapters = (id) => {
        setAdminPendingChapters(adminPendingTasks[id].pendingChapters);
        setSelected(id);
    }

    const setFilter = (filter) => {
        dispatch(setProjectsFilter(filter));
    }

    const callBookGraph = (id, book_name) => {
        setCurrentBook(id);
        setBookName(book_name);
        books && dispatch(getGraph(id));
    }

    const options = {
        chart: {
            zoomType: 'x'
        },
        title: {
            text: bookName
        },
        subtitle: {
            text: document.ontouchstart === undefined ?
                'Click and drag in the plot area to zoom in' : 'Pinch the chart to zoom in'
        },
        xAxis: {
            type: 'datetime'
        },
        yAxis: {
            title: {
                text: 'Book Progress'
            }
        },
        legend: {
            enabled: false
        },
        plotOptions: {
            area: {
                fillColor: {
                    linearGradient: {
                        x1: 0,
                        y1: 0,
                        x2: 0,
                        y2: 1
                    },
                    stops: [
                        [0, Highcharts.getOptions().colors[0]],
                        [1, Highcharts.color(Highcharts.getOptions().colors[0]).setOpacity(0).get('rgba')]
                    ]
                },
                marker: {
                    radius: 5
                },
                lineWidth: 1,
                states: {
                    hover: {
                        lineWidth: 1
                    }
                },
                threshold: null
            }
        },

        series: [{
            type: 'area',
            name: 'Book Progress',
            data: graph
        }]
    }

    return (
        <>
            <div>
                <div className="section-body mt-3">
                    <div className="container-fluid">
                        <div className="row clearfix">
                            <div className="col-lg-12">
                                <div className="mb-4">
                                    <h4>Welcome to Ehya Education Services!</h4>
                                    <small>RETHINK . REFINE . REVIVE</small>
                                </div>
                            </div>
                        </div>

                        {localStorage.role != 'superAdmin' && <div className="row clearfix">
                            <div className="col-md-3 col-sm-12">
                                <div className="row clearfix row-deck">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Pending Tasks</h3>
                                            </div>
                                            <div className="card-body">
                                                <h5 className="number mb-0 font-50 counter text-center" style={{ color: '#dc3545' }}>{pendingTasks?.length}</h5>
                                                {/* <span className="font-12">Measure How Fast... <a href="#">More</a></span> */}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Pending Tasks</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="table-responsive" style={{ 'maxHeight': '386px', 'overflow': 'auto' }}>
                                                    <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                                                        <thead className="bthead">
                                                            <tr>
                                                                <th>Book Name</th>
                                                                {/* <th>Phase</th> */}
                                                                <th>Chapter Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="btbody">
                                                            {pendingTasks && pendingTasks.map((pendingTask, key) => {
                                                                return <tr className="on-click" key={key} onClick={() => history.push(`/books/${pendingTask.bookId}/chapter/${pendingTask.chapterId}`)}>
                                                                    <td>{pendingTask.bookName}</td>
                                                                    <td>{pendingTask.chapterName}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <div className="row clearfix">
                            <div className="col-md-6 col-sm-12">
                                <div className="row clearfix row-deck">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h3 className="card-title">Users with Pending Tasks</h3>
                                            </div>

                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-lg-12">
                                                        <div className="table-responsive" style={{ 'maxHeight': '386px', 'overflow': 'auto' }}>
                                                            <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                                                                <thead className="bthead">
                                                                    <tr>
                                                                        <th>User Name</th>
                                                                        {/* <th>Phase</th> */}
                                                                        <th>User Email</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className="btbody">
                                                                    {adminPendingTasks && adminPendingTasks.map((adminPendingTask, key) => {
                                                                        return <tr className="on-click" key={key} onClick={() => updatePendingChapters(key)}>
                                                                            <td className={selected == key ? 'selected_book' : ''}>{adminPendingTask.name}</td>
                                                                            <td>{adminPendingTask.email}</td>
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="col-md-6 col-sm-12">
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">Pending Tasks</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-lg-12">
                                                <div className="table-responsive" style={{ 'maxHeight': '386px', 'overflow': 'auto' }}>
                                                    <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                                                        <thead className="bthead">
                                                            <tr>
                                                                <th>Book Name</th>
                                                                {/* <th>Phase</th> */}
                                                                <th>Chapter Name</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className="btbody">
                                                            {adminPendingChapters && adminPendingChapters.map((adminPendingChapter, key) => {
                                                                return <tr className="on-click" key={key} onClick={() => history.push(`/books/${adminPendingChapter.bookId}/chapter/${adminPendingChapter.chapterId}`)}>
                                                                    <td>{adminPendingChapter.bookName}</td>
                                                                    <td>{adminPendingChapter.chapterName}</td>
                                                                </tr>
                                                            })}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator' || localStorage.role == 'academicHead') && <div>
                            <div className="row clearfix row-deck">
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link onClick={() => setFilter('all')} to="/books"><h3 className="card-title">Total Books</h3></Link>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="number mb-0 font-32 counter">{stats.total}</h5>
                                            {/* <span className="font-12">Measure How Fast... <a href="#">More</a></span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link onClick={() => setFilter('complete')} to="/books"><h3 className="card-title">Completed Books</h3></Link>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="number mb-0 font-32 counter">{stats.completed}</h5>
                                            {/* <span className="font-12">Measure How Fast... <a href="#">More</a></span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link onClick={() => setFilter('writing')} to="/books"><h3 className="card-title">Writing Phase</h3></Link>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="number mb-0 font-32 counter">{stats.writing}</h5>
                                            {/* <span className="font-12">Measure How Fast... <a href="#">More</a></span> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xl-3 col-lg-4 col-md-6">
                                    <div className="card">
                                        <div className="card-header">
                                            <Link onClick={() => setFilter('designing')} to="/books"><h3 className="card-title">Designing Phase</h3></Link>
                                        </div>
                                        <div className="card-body">
                                            <h5 className="number mb-0 font-32 counter">{stats.designing}</h5>
                                            {/* <span className="font-12">Measure How Fast... <a href="#">More</a></span> */}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="row clearfix">
                                <div className="col-12 col-sm-12">
                                    <div className="card">
                                        <div className="card-header">
                                            <h3 className="card-title">Books Summary</h3>
                                        </div>
                                        <div className="card-body">
                                            <div className="row">
                                                <div className="col-lg-6">
                                                    <div className="table-responsive" style={{ 'maxHeight': '386px', 'overflow': 'auto' }}>
                                                        <table className="table table-hover table-striped text-nowrap table-vcenter mb-0">
                                                            <thead className="bthead">
                                                                <tr>
                                                                    <th>Book Name</th>
                                                                    {/* <th>Phase</th> */}
                                                                    <th>Team</th>
                                                                    <th>Progress</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody className="btbody">
                                                                {books && books.map((book, key) => {
                                                                    if (book.isDeleted == 0 && book.bookPhase != "complete") {
                                                                        return <tr key={key}>
                                                                            <td><a onClick={() => callBookGraph(book.id, book.bookName)} className={currentBook == book.id && 'selected_book'} href="javacript:void(0);">{book.bookName}</a></td>
                                                                            {/* <td>{book.bookPhase}</td> */}
                                                                            <td>
                                                                                <ul className="list-unstyled team-info sm margin-0 w150">
                                                                                    <li>
                                                                                        <img src={server_url + book.academicHeadImage} data-toggle="tooltip" title={book.academicHeadName + '\nAcademic Head'} alt={book.academicHeadName} />
                                                                                    </li>
                                                                                    {book.reviewers.map(reviewer => {
                                                                                        return <li>
                                                                                            <img src={server_url + reviewer.reviewerImage} data-toggle="tooltip" title={reviewer.reviewerName + '\nReviewer'} alt={reviewer.reviewerName} />
                                                                                        </li>
                                                                                    })}

                                                                                    {book.authors.map(author => {
                                                                                        return <li>
                                                                                            <img src={server_url + author.authorImage} data-toggle="tooltip" title={author.authorName + '\nAuthor'} alt={author.authorName} />
                                                                                        </li>
                                                                                    })}

                                                                                    {book.designers.map(designer => {
                                                                                        return <li>
                                                                                            <img src={server_url + designer.designerImage} data-toggle="tooltip" title={designer.designerName + '\nDesigner'} alt={designer.designerName} />
                                                                                        </li>
                                                                                    })}
                                                                                    {/* <li className="ml-2"><span>2+</span></li> */}
                                                                                </ul>
                                                                            </td>
                                                                            <td>
                                                                                <div>
                                                                                    <div className="clearfix">
                                                                                        <div className="float-left"><strong>{book.bookPercentage}%</strong></div>
                                                                                        <div className="float-right"><small className="text-muted">Phase: {book.bookPhase}</small></div>
                                                                                    </div>
                                                                                    <div className="progress progress-xs">
                                                                                        <div className="progress-bar bg-azure" role="progressbar" style={{ width: book.bookPercentage + '%' }} aria-valuenow={42} aria-valuemin={0} aria-valuemax={100} />
                                                                                    </div>
                                                                                </div>
                                                                            </td>
                                                                        </tr>
                                                                    }

                                                                })}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </div>
                                                <div className="col-lg-6">
                                                    <HighchartsReact
                                                        highcharts={Highcharts}
                                                        options={options}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>}

                    </div>
                </div>
            </div>



        </>
    );
}

export default Home;