import { ALERT_MESSAGE, ERRORS_GET, LOGOUT, SERVER_ERROR } from "./actions/types";
import { logoutUser } from "./actions/authActions";

export const check_response = response => async dispatch => {

    if (response && response.status == 422) {
        dispatch({
            type: ERRORS_GET,
            payload: response.data.errors
        });
    } else if (response && response.status == 400){
        dispatch(alertMessage(response.data));
    } else if (response && response.status == 203) {
        dispatch(logoutUser());
    } else if (response && (response.status == 500 || response.status == 500)) {
        dispatch({
            type: SERVER_ERROR,
            payload: 'Server Error! Please contact Administator'
        });
    } else {
        console.log(response);
    }

}

export const alertMessage = (response) => async dispatch => {
    dispatch({
        type: ALERT_MESSAGE,
        payload: {message: response.responseMessage, flag: response.responseFlag}
    });
}

export function getCurrentDate(separator = '') {

    let newDate = new Date();
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();

    return `${date}${separator}${month < 10 ? `0${month}` : `${month}`}${separator}${year}`
}

export const has_permission = id => {
    if (is_admin()) {
        return true;
    }
    var userPermissions = localStorage.userPermissions;
    if (userPermissions) {
        userPermissions = JSON.parse(localStorage.userPermissions);
        return userPermissions.includes(id);
    } else {
        return false;
    }
}

export const isLogin = () => {

}

export const is_admin = () => {
    if (localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator')
        return true;
    else
        return false;
}

export const check_response_status = res => async dispatch => {
    if (res.status == 203) {
        localStorage.removeItem('usertoken');
        localStorage.removeItem('role');
        dispatch({
            type: LOGOUT,
            payload: true
        });
    }
    else if (res.status == 500) {
        dispatch({
            type: SERVER_ERROR,
            payload: 'Server Error! Please contact Administator'
        });
    }
}

export const date_time_to_date_format = datetime => {
    if (datetime) {
        datetime = datetime.replace('T', ' ');
        datetime = datetime.replace('.000000Z', '');
        let dateTimeParts = datetime.split(/[- :]/);
        dateTimeParts[1]--;
        const dateObject = new Date(...dateTimeParts);
        return dateObject.toDateString('en-US');
    } else {
        return "";
    }
}


// export const base_url = 'https://dev.portalapi.ehya.com.pk/api/';
// export const base_url_file = 'https://dev.portalapi.ehya.com.pk/api/download-file';
// export const server_url = 'https://dev.portalapi.ehya.com.pk';

// export const base_url = 'https://ees.itinitial.com/api/';
// export const base_url_file = 'https://ees.itinitial.com/api/download-file';
// export const server_url = 'https://ees.itinitial.com';

export const base_url = 'https://portalapi.ehya.com.pk/api/';
export const base_url_file = 'https://portalapi.ehya.com.pk/api/download-file';
export const server_url = 'https://portalapi.ehya.com.pk';
export const server_download_url = 'https://portalapi.ehya.com.pk/download';

// export const base_url = 'http://127.0.0.1:8000/api/';
// export const base_url_file = 'http://127.0.0.1:8000/api/download-file';
// export const server_url = 'http://127.0.0.1:8000';
