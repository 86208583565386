import { useDispatch, useSelector } from "react-redux";
import {BrowserRouter as Router, Redirect, Route, Switch} from "react-router-dom";
import Home from "./components/pages/Home";
import Sidebar from "./components/elements/Sidebar";
import Users from "./components/pages/Users";
import Pagetop from "./components/users/Pagetop";
import FooterSection from "./components/users/FooterSection";
import Login from "./components/auth/Login";
import Books from "./components/pages/Books";
import Profile from "./components/pages/Profile";
import { useEffect } from "react";
import { check_login_status, get_user_info } from "./actions/authActions";
import Comments from "./components/pages/Comments";
import Categories from "./components/pages/Categories";

const App = () => {
    const dispatch = useDispatch();
    const login = useSelector((state) => state.auth.login);

    useEffect(() => {

        dispatch(check_login_status());

    }, []);

    return (
        <>
            {!login && <Login />}
            {login && <Router>
                {window.location.pathname == "/login" && <Redirect to='/'/>}
                <div className="main_content">
                    <Sidebar />
                    <div className="page">
                        <Pagetop />
                        <Switch>
                            <Route exact path="/" component={Home} />
                            <Route path="/users" component={Users} />
                            <Route path="/books" component={Books} />
                            <Route path="/categories" component={Categories} />
                            <Route path="/chapters" component={Books} />
                            <Route path="/labels" component={Comments} />
                            <Route path="/profile" component={Profile} />
                        </Switch>
                        <FooterSection />
                    </div>
                </div>
            </Router>
            }
        </>
    );
}

export default App;
