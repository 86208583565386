import {
    GET_CATEGORY,
    CREATE_CATEGORY,
    UPDATE_CATEGORY,
    DELETE_CATEGORY,
    GET_CATEGORIES,
    CATEGORY_ERRORS,
    EMPTY_CATEGORY_ERRORS,
    CATEGORY_CREATED
} from "./types";

import axios from 'axios';
import { base_url, check_response } from "../utils";

// get all comments
export const getCategories = () => async dispatch => {

    axios.post(`${base_url}categories`,
        {},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CATEGORIES,
                    payload: response.data.categories
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}
// get a CATEGORY
export const getCategory = (id) => async dispatch => {

    axios.post(`${base_url}categories/get-category`,
        { "id": id },
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: GET_CATEGORY,
                    payload: response.data.category
                });
                console.log("category" + response.data.category);
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// create a CATEGORY
export const createCategory = (category) => async dispatch => {

    axios.post(`${base_url}categories/add-category`,
    category,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: CREATE_CATEGORY,
                    payload: response.data.category
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update a CATEGORY
export const updateCategory = (category) => async dispatch => {

    axios.post(`${base_url}categories/update-category`,
        category,
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: UPDATE_CATEGORY,
                    payload: response.data.category
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// Empty Errors
export const emptyCategoryErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_CATEGORY_ERRORS,
        payload: null
    })
}

// CATEGORY Created
export const categoryCreated = () => async dispatch => {
    dispatch({
        type: CATEGORY_CREATED,
        payload: null
    })
}