import { Link, useRouteMatch } from "react-router-dom";

const CommentItem = ({comment}) => {
    let match = useRouteMatch();
    return ( 
        
        <tr>
            <td><a href="#">{comment.labelName}</a></td>
            <td><div style={{'backgroundColor': comment.labelColor, 'height': '20px', 'width': '50%'}}></div></td>
            <td><span>{comment.createdAt}</span></td>
            {/* <td><span>{chapter.chapterDescription}</span></td> */}
            <td class="text-right">
            {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <Link class="btn btn-sm btn-link" to={`${match.url}/update-label/${comment.id}`} data-toggle="tooltip" title="Edit"><i class="fa fa-edit"></i></Link>}
                {/* <a class="btn btn-sm btn-link hidden-xs js-sweetalert" data-type="confirm" onClick={() => deleteChap(chapter.id)} data-toggle="tooltip" title="Delete"><i class="fa fa-trash"></i></a> */}
            </td>
        </tr>
     );
}
 
export default CommentItem;