import { useRouteMatch } from "react-router";
import { Link } from "react-router-dom";

const CommentsHead = () => {
    let match = useRouteMatch();
    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item">
                                    <Link className="nav-link active" data-toggle="tab" to={`${match.url}`}>Labels</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`${match.url}/add-label`}>Add Label</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
 
export default CommentsHead;