import { LOGIN, LOGOUT } from "../actions/types";

const initialState = {
    login: false,
    error: null
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case LOGIN:
            return {
                login: payload.login,
                error: payload.error
            }
        case LOGOUT:
            return {
                login: false,
                error: ''
            }
        default:
            return state;
    }
}