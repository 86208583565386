import { useSelector } from "react-redux";

const Alert = () => {
    const response = useSelector(state => state.globals.response);
    console.log(response);
    return (
        response && <div class={`alert alert-${response && response.flag}`} role="alert">
            {response && response.message}
        </div>
    );
}

export default Alert;