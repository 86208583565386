import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import { getChapters } from "../../actions/chapterActions";
import { showBookHead } from "../../actions/globalActions";
import { getProject } from "../../actions/projectActions";
import AddChapter from "./AddChapter";
import BookDetailItem from "./BookDetailItem";
import ChapterDetail from "./ChapterDetail";
import NewChapterDetail from "./NewChapterDetail";
import UpdateChapter from "./UpdateChapter";

const DetailBook = () => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    let { bookId } = useParams();
    const book = useSelector((state) => state.books.book);
    const chapters = useSelector(state => state.chapters.chapters);
    const pieChartData = useSelector(state => state.chapters.pieChartData);
    const [fullBook, setFullBook] = useState([]);

    useEffect(() => {
        dispatch(getProject(bookId));
        dispatch(getChapters(bookId));
        dispatch(showBookHead(false));
    }, []);

    useEffect(() => {
        if (chapters.length > 0) {
            setFullBook(chapters.filter(chapterr => chapterr.isFullBook == 1));
        }
    }, [chapters]);

    return (
        <div className="row clearfix">
            <div className="col-md-12">
                <div className="d-flex justify-content-between align-items-center">
                    {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') ? <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item">
                            <Link className="nav-link" data-toggle="tab" to={`${match.url}`}>Edit Chapters</Link>
                        </li>
                        <li className="nav-item">
                            <Link className="nav-link" data-toggle="tab" to={`${match.url}/add-chapter`}>Add Chapter</Link>
                        </li>
                    </ul> : <ul className="nav nav-tabs page-header-tab">
                        <li className="nav-item">
                            <a href="javascript:void(0);" className="nav-link active" data-toggle="tab">Chapters</a>
                        </li>
                    </ul>}
                    {/* <div className="header-action d-md-flex">
                        <div className="input-group mr-2">
                            <input type="text" className="form-control" placeholder="Search..." />
                        </div>
                    </div> */}
                </div>
            </div>
            <Switch>
                <Route path={`${match.path}/chapter/:chapId`}>
                { book?.isNew == 1 ? <NewChapterDetail book={book} chapters={chapters} pieChartData={pieChartData} bookIdd={bookId} /> :
                    <ChapterDetail book={book} chapters={chapters} pieChartData={pieChartData} bookIdd={bookId} />}
                </Route>
                <Route path={`${match.path}/update-chapter/:chapId`}>
                    <UpdateChapter bookIdd={bookId} />
                </Route>
                <Route path={`${match.path}/add-chapter`}>
                    <AddChapter bookIdd={bookId} />
                </Route>
                <Route path={match.path}>
                    <div className="col-12">
                        {fullBook.length > 0 && <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">{book && book.bookName} (Full Book)</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-vcenter mb-0 text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Book</th>
                                            <th>Progress</th>
                                            <th>Author</th>
                                            <th>Phase</th>
                                            <th>Created At</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <BookDetailItem key={fullBook[0].id} chapter={fullBook[0]} />
                                    </tbody>
                                </table>
                            </div>
                        </div>}
                        <div className="card">

                            <div className="card-header">
                                <h3 className="card-title">{book && book.bookName} (Chapters)</h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-hover table-striped table-vcenter mb-0 text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Chapter</th>
                                            <th>Progress</th>
                                            <th>Author</th>
                                            <th>Phase</th>
                                            <th>Created At</th>
                                            <th>Description</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {chapters && chapters.map(chapter => {
                                            if (chapter.isFullBook != 1) {
                                                return <BookDetailItem key={chapter.id} chapter={chapter} />
                                            }
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </Route>
            </Switch>
        </div>
    );
}

export default DetailBook;