import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { check_login_status, loginUser } from "../../actions/authActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import Alert from "../elements/Alert";
import {BrowserRouter as Router, Redirect} from "react-router-dom";


const Login = () => {
    const error = useSelector(state => state.auth.error);

    const errors = useSelector(state => state.globals.errors);
    const login = useSelector((state) => state.auth.login);

    const dispatch = useDispatch();
    const [username, setUserame] = useState("");
    const [password, setPassword] = useState("");

    useEffect(() => {
        dispatch(check_login_status());
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
    }, []);

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const login_obj = {
            username,
            password
        };

        dispatch(loginUser(login_obj));
    }

    return (
        <>
            <Router>
        {!localStorage.usertoken && window.location.pathname != "/login" && <Redirect to='/login'/>}
            </Router>
        <div className="auth">
            <div className="auth_left">
                <div className="card">
                    <div className="text-center mb-2">
                        <a className="header-brand" href="javascript:void(0)"><img style={{ 'width': '70px', 'borderRadius': '50px' }} src="assets/images/logo.png" className="brand-logo" /></a>
                    </div>
                    <div className="card-body">
                        <div className="card-title">Login to your account</div>
                        <form onSubmit={submitForm} >
                            <div className="form-group">
                                <input type="text" className="form-control" placeholder="Username" required
                                    value={username} onChange={(e) => setUserame(e.target.value)} />
                            </div>
                            <div className="form-group">
                                <input type="password" className="form-control" placeholder="Password" required
                                    value={password} onChange={(e) => setPassword(e.target.value)} />
                            </div>
                            {/* <div className="form-group">
                                <label className="custom-control custom-checkbox">
                                    <input type="checkbox" className="custom-control-input" />
                                    <span className="custom-control-label">Remember me</span>
                                </label>
                            </div> */}
                            <div className="form-footer">
                                <button className="btn btn-primary btn-block">Sign in</button>
                            </div>
                        </form>
                    </div>
                    <div class="text-center text-red">
                        <Alert />
                    </div>
                </div>
            </div>
            <div className="auth_right full_img" />
        </div>
        </>

    );
}

export default Login;