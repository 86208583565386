import { Route, Switch, useRouteMatch } from "react-router";
import CategoryAdd from "./CategoryAdd";
import CategoriesList from "./CategoriesList";
import CategoryUpdate from "./CategoryUpdate";

const CategoriesContent = () => {
    let match = useRouteMatch();
    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="tab-content">
                    <Switch>
                        <Route path={`${match.path}/add-category`}>
                            <CategoryAdd />
                        </Route>
                        <Route path={`${match.path}/update-category/:id`}>
                            <CategoryUpdate />
                        </Route>
                        <Route path={match.path}>
                            <CategoriesList />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
 
export default CategoriesContent;