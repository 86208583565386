import {
  GET_PROJECT,
  CREATE_PROJECT,
  UPDATE_PROJECT,
  DELETE_PROJECT,
  GET_PROJECTS,
  PROJECT_CREATED,
  PROJECT_ERRORS,
  EMPTY_PROJECT_ERRORS,
  GET_PROJECT_GRAPH,
  SET_PROJECT_FILTER
} from "../actions/types";

const initialState = {
  books: [],
  book: null,
  graph: null,
  bookName: null,
  errors: [],
  isCreated: false,
  filter: 'all'
};
export default (state = initialState, { type, payload }) => {


  switch (type) {
    case GET_PROJECTS:
      return {
        ...state,
        books: payload
      }
    case CREATE_PROJECT:
      return {
        ...state,
        books: [payload, ...state.books],
        isCreated: true
      };
    case GET_PROJECT:
      return {
        ...state,
        book: payload,
      };
    case UPDATE_PROJECT:
      return {
        ...state,
        books: state.books.map((book) =>
          book.id == payload.id ? payload : book
        ),
        isCreated: true
      };
    case DELETE_PROJECT:
      return {
        ...state,
        books: state.books.filter((book) => book.id != payload)
      };
    case PROJECT_CREATED:
      return {
        ...state,
        isCreated: false
      };
    case PROJECT_ERRORS:
      return {
        ...state,
        errors: payload
      };
    case EMPTY_PROJECT_ERRORS:
      return {
        ...state,
        errors: []
      };
    case GET_PROJECT_GRAPH:
      return {
        ...state,
        graph: payload.graphData,
        bookName: payload.name
      };
    case SET_PROJECT_FILTER:
      return {
        ...state,
        filter: payload
      };
    default:
      return state;
  }
};