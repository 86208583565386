import { combineReducers } from "redux";
import authReducer from "./authReducer";
import chapterReducer from "./chapterReducer";
import commentReducer from "./commentReducer";
import globalReducer from "./globalReducer";
import projectReducer from "./projectReducer";
import userReducer from "./userReducer";
import profileReducer from "./profileReducer";
import categoryReducer from "./categoryReducer";

export default combineReducers({
  auth: authReducer,
  users: userReducer,
  books: projectReducer,
  chapters: chapterReducer,
  comments: commentReducer,
  categories: categoryReducer,
  globals: globalReducer,
  profile: profileReducer,
});