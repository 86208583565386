import {
    GET_COMMENT,
    CREATE_COMMENT,
    UPDATE_COMMENT,
    DELETE_COMMENT,
    GET_COMMENTS,
    COMMENT_ERRORS,
    EMPTY_COMMENT_ERRORS,
    COMMENT_CREATED,
} from "../actions/types";

const initialState = {
    comments: [],
    comment: null,
    errors: [],
    isCreated: false
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case GET_COMMENTS:
            return {
                ...state,
                comments: payload
            }
        case CREATE_COMMENT:
            return {
                ...state,
                comments: [payload, ...state.comments],
                isCreated: true
            };
        case GET_COMMENT:
            return {
                ...state,
                comment: payload,
            };
        case UPDATE_COMMENT:
            return {
                ...state,
                comments: state.comments.map((comment) =>
                    comment.id == payload.id ? payload : comment
                ),
                isCreated: true
            };
        case DELETE_COMMENT:
            return {
                ...state,
                comments: state.comments.filter((comment) => comment.id != payload),
            };
            case COMMENT_ERRORS:
              return {
                ...state,
                errors: payload
              }
            case EMPTY_COMMENT_ERRORS:
              return {
                ...state,
                errors: []
              }
            case COMMENT_CREATED:
              return {
                ...state,
                isCreated: false
              }
        default:
            return state;
    }
};