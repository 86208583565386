import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getUsers } from "../../actions/userAction";
import DataTable from 'react-data-table-component';
import UserItem from "./UserItem";
import { server_url } from "../../utils";

const ListUsers = () => {
    const dispatch = useDispatch();
    const userFilter = useSelector(state => state.users.filter);
    const userss = useSelector((state) => state.users.users);
    const [users, setUsers] = useState([]);

    useEffect(() => {
        dispatch(getUsers());
    }, []);

    useEffect(() => {
    }, [userss]);

    useEffect(() => {
        let result = [];
        if (userFilter != 'all') {
            result = userss.filter(user => user.isActive == 1);
        } else {
            result = userss;
        }
        setUsers(result);
    }, [userFilter]);

    useEffect(() => {
        let result = [];
        if (userFilter != 'all') {
            result = userss.filter(user => user.isActive == 1);
        } else {
            result = userss;
        }
        setUsers(result);

        if (userss.length > 0) {
            // const script = document.createElement("script");
            // script.src = "/assets/js/datatable.js";
            // script.async = true;
            // document.body.appendChild(script);
        }
    }, [userss]);


    return (
        <div className="tab-pane fade show active" id="list" role="tabpanel">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="table-responsive" id="users">
                        
                        
                        <table id="user_table" className="table table-hover table-vcenter text-nowrap table_custom border-style list">
                            <thead>
                                <tr>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {users && users.map(user =>
                                    <UserItem key={user.id} user={user} />
                                )}
                            </tbody>
                        </table>
                    
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ListUsers;