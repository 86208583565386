import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageHeading } from "../../actions/globalActions";
import CommentsContent from "../comments/CommentsContent";
import CommentsHead from "../comments/CommentsHead";

const Comments = () => {
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setPageHeading('Comments'));
    })
    return ( 
        <>
            <CommentsHead />
            <CommentsContent />
        </>
     );
}
 
export default Comments;