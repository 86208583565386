import { useEffect, useState } from "react";
import NumberFormat from "react-number-format";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { getUser, updateUser, userCreated } from "../../actions/userAction";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";

const UpdateUser = () => {
    let history = useHistory();
    const dispatch = useDispatch();
    const user = useSelector((state) => state.users.user);
    const { id } = useParams();
    const roles = useSelector(state => state.users.roles);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.users.isCreated);


    const [name, setName] = useState("");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [role, setRole] = useState("");
    const [gender, setGender] = useState("");
    const [phone, setPhone] = useState("");
    const [email, setEmail] = useState("");
    const [employeeId, setEmployeeId] = useState("");
    const [cnic, setCnic] = useState("");
    const [isActive, setIsActive] = useState("");

    useEffect(() => {
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        loadUser();
    }, []);

    useEffect(() => {
        if (isCreated) {
            dispatch(userCreated());
            history.push('/users');
        }
    }, [isCreated]);

    useEffect(() => {
        if (user) {
            setName(user.name);
            setUsername(user.username);
            setPassword(user.password);
            setRole(user.role);
            setGender(user.gender);
            setPhone(user.phone);
            setCnic(user.cnic);
            setEmail(user.email);
            setEmployeeId(user.employeeId);
            setIsActive(user.isActive);
        }
    }, [user]);

    const loadUser = () => {
        dispatch(getUser(id));
    };
    
    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_user = {
            id,
            name,
            username,
            password,
            role,
            cnic,
            phone,
            email,
            gender,
            employeeId,
            isActive
        };
        dispatch(updateUser(new_user));
    };
    return (
        <div className="tab-pane fade show active" id="addnew" role="tabpanel">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={submitForm}>
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Name</label>
                                            <input type="text" required className="form-control" placeholder="Enter Name"
                                                value={name} onChange={(e) => setName(e.target.value)} />
                                            <p className="error">{errors && errors.name}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Username</label>
                                            <input type="text" required className="form-control" placeholder="Enter Username"
                                                value={username} onChange={(e) => setUsername(e.target.value)} />
                                            <p className="error">{errors && errors.username}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Password</label>
                                            <input type="text" className="form-control" placeholder="Enter Password"
                                                value={password} onChange={(e) => setPassword(e.target.value)} />
                                            <p className="error">{errors && errors.password}</p>
                                        </div>
                                    </div>
                                    
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Email</label>
                                            <input type="email" required className="form-control" placeholder="Enter Email"
                                                value={email} onChange={(e) => setEmail(e.target.value)} />
                                                <p className="error">{errors && errors.email}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Employee Id</label>
                                            <input type="text" className="form-control" placeholder="Enter Employee Id"
                                                value={employeeId} onChange={(e) => setEmployeeId(e.target.value)} />
                                            <p className="error">{errors && errors.employeeId}</p>
                                        </div>
                                    </div>

                                    {/* <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">CNIC</label>
                                            <NumberFormat required className="form-control" format="#####-#######-#"
                                                value={cnic} onChange={(e) => setCnic(e.target.value)} />
                                            <p className="error">{errors && errors.cnic}</p>
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Phone #</label>
                                            <NumberFormat required className="form-control" format="+92-3#########" allowEmptyFormatting
                                                value={phone} onChange={(e) => setPhone(e.target.value)} />
                                            <p className="error">{errors && errors.phone}</p>
                                        </div>
                                    </div> */}

                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Gender</label>
                                            <select class="form-control show-tick" required
                                                value={gender} onChange={(e) => setGender(e.target.value)}>
                                                <option value="">--Gender--</option>
                                                <option value="male">Male</option>
                                                <option value="female">Female</option>
                                            </select>
                                            <p className="error">{errors && errors.gender}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Role</label>
                                            <select class="form-control show-tick" required
                                                value={role} onChange={(e) => setRole(e.target.value)}>
                                                <option value="">--Select Role--</option>
                                                {Object.keys(roles).map((role, key) => { return <option key={key} value={role}>{roles[role]}</option> })}
                                            </select>
                                            <p className="error">{errors && errors.role}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Status</label>
                                            <select class="form-control show-tick" required
                                                value={isActive} onChange={(e) => setIsActive(e.target.value)}>
                                                <option value="">--Status--</option>
                                                <option value="1">Enabled</option>
                                                <option value="0">Disabled</option>
                                            </select>
                                            <p className="error">{errors && errors.isActive}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <textarea type="text" required className="form-control" rows={4} placeholder="Enter Address"
                                                value={address} onChange={(e) => setAddress(e.target.value)} />
                                        </div>
                                    </div> */}
                                    {/* <div className="col-lg-12">
                                        <input type="file" className="dropify" />
                                    </div> */}
                                    <div className="col-lg-12 mt-3">
                                        <button type="submit" className="btn btn-primary">Update</button>
                                        <button type="button" onClick={() => history.goBack()} className="btn btn-default float-right">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default UpdateUser;