import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCategories } from "../../actions/categoryActions";
import { showBookHead } from "../../actions/globalActions";
import { getProjects } from "../../actions/projectActions";
import BookItem from "./BookItem";

const ListBooks = () => {
    const dispatch = useDispatch();
    const filter = useSelector(state => state.books.filter);
    const bookss = useSelector((state) => state.books.books);
    const categories = useSelector(state => state.categories.categories);
    const [filters, setFilters] = useState({});
    const [books, setBooks] = useState([]);

    const setPhase = (bookPhase) => {
        setFilters({ ...filters, bookPhase })
    }
    const setCategory = (categoryId) => {
        setFilters({ ...filters, categoryId });
    }

    useEffect(() => {
        dispatch(getCategories());
        setFilters({bookPhase: "not_completed"});
    }, []);

    useEffect(() => {
        dispatch(getProjects({ filters }));
    }, [filters]);

    useEffect(() => {
        dispatch(getProjects({bookPhase: "not_completed"}));
        dispatch(showBookHead(true));
    }, []);



    useEffect(() => {
        let result = [];
        if (filter != 'all') {
            result = bookss.filter(book => book.bookPhase == filter);
        } else {
            result = bookss;
        }
        setBooks(result);
    }, [bookss]);

    useEffect(() => {
        let result = [];
        if (filter != 'all') {
            result = bookss.filter(book => book.bookPhase == filter);
        } else {
            result = bookss;
        }
        setBooks(result);
    }, [filter]);

    return (
        <>
            <div className="input-group mr-2" style={{    marginTop: '-20px', marginBottom: '5px'}}>
                <select className="btn-hallow mr-2" style={{ borderRadius: '0.25rem', margin: 'auto' }}
                    onChange={(e) => { setCategory(e.target.value) }}>
                    <option value="">All categories</option>
                    {categories.map(category =>
                        <option value={category.id}>{category.categoryName}</option>
                    )}
                </select>

                <select className="btn-hallow mr-2" style={{ borderRadius: '0.25rem' }}
                    onChange={(e) => { setPhase(e.target.value) }}>
                    <option value="">All Phases</option>
                    <option selected value="not_completed">Not Completed</option>
                    <option value="complete">Completed</option>
                    <option value="writing">Writing</option>
                    <option value="designing">Designing</option>
                </select>
            </div>
            <div className="tab-pane fade show active" id="Project-OnGoing" role="tabpanel">



                <div className="row">
                    {books && books.map(book =>
                        <BookItem key={book.id} book={book} />
                    )}
                </div>
            </div>
        </>

    );
}

export default ListBooks;