import { useDispatch } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { server_url } from "../../utils";

const UserItem = ({ user }) => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    
    return (
        <tr className={user.role}>
            <td className="width35 hidden-xs">
                <a href="javascript:void(0);" className="mail-star"><i className={`fa fa-star`} /></a>
            </td>
            <td className="text-center width40">
                <div className="avatar d-block">
                    <img className="avatar" src={server_url + user.image} data-toggle="tooltip" title={user.name} alt={user.name} />
                </div>
            </td>
            <td>
                <div><a href="javascript:void(0);">{user.name}</a></div>
                <div className="text-muted">{user.username}</div>
            </td>
            <td className="hidden-xs">
                <div className="text-muted">{user.role} </div>
            </td>
            {/* <td className="hidden-sm">
                <div className="text-muted">{user.address}</div>
            </td> */}
            <td className="text-right">
                <Link className="btn btn-sm btn-link" to={`${match.url}/update-user/${user.id}`} data-toggle="tooltip" title="Edit"><i className="fa fa-edit" /></Link>
                {/* <a className="btn btn-sm btn-link" href="javascript:void(0)" data-toggle="tooltip" title="Detail"><i className="fa fa-info" /></a> */}
                {/* <a className="btn btn-sm btn-link hidden-xs js-sweetalert" data-type="confirm" onClick={() => deleteUser1(user.id)} data-toggle="tooltip" title="Delete"><i className="fa fa-trash" /></a> */}
            </td>
        </tr>
    );
}

export default UserItem;