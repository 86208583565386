import {
    EMPTY_PROFILE_ERRORS,
    PROFILE_ERRORS,
    PROFILE_GET,
    PROFILE_UPDATE,
    UPDATE_PROGRESS
} from "./types";
import axios from "axios";
import { alertMessage, base_url, check_response } from "../utils";

// get profile
export const getProfile = (id) => async dispatch => {

    axios.post(`${base_url}get-profile`,
        {},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: PROFILE_GET,
                    payload: response.data.profile
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update profile
export const updateProfile = (profile) => async dispatch => {

    axios.post(`${base_url}update-profile`,
        profile,
        { headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` } })
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: PROFILE_UPDATE,
                    payload: response.data.profile
                });
                dispatch(alertMessage(response.data));
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// update Profile Image

export const updateProfileImage = (image) => async dispatch => {

    const options = {
        onUploadProgress: (progressEvent) => {
            const { loaded, total } = progressEvent;
            let percent = Math.floor((loaded * 100) / total)
            if (percent <= 100) {
                dispatch({
                    type: UPDATE_PROGRESS,
                    payload: percent
                });
            }
        },
        headers: { 'Content-Type': 'multipart/form-data', Authorization: `Bearer ${localStorage.usertoken}` }
    }
    axios.post(`${base_url}update-profile-image`,
        image, options)
        .then(response => {
            if (response.status == 200) {
                dispatch({
                    type: PROFILE_UPDATE,
                    payload: response.data.profile
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// Empty Errors
export const emptyProfileErrors = () => async dispatch => {
    dispatch({
        type: EMPTY_PROFILE_ERRORS,
        payload: null
    })
}