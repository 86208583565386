import axios from "axios";
import { base_url, check_response } from "../utils";
import { LOGIN, LOGIN_FAIL, LOGOUT, SET_PROFILE } from "./types";


// login User
export const loginUser = (login_obj) => async dispatch => {
    axios.post(`${base_url}auth/login`,
        login_obj,
        { headers: { 'Content-Type': 'application/json' } })
        .then(response => {
            if (response.status == 200) {
                localStorage.setItem('usertoken', response.data.token);
                dispatch(get_user_info());
            } else {
                dispatch(check_response(response))
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });
}

// logout user
export const logoutUser = () => async dispatch => {
    localStorage.removeItem('usertoken');
    localStorage.removeItem('role');
    localStorage.removeItem('userId');
    dispatch({
        type: LOGOUT,
        payload: true
    });
}

export const get_user_info = () => async dispatch => {

    axios.post(`${base_url}get-profile`,
        {},
        {
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` }
        })
        .then(response => {
            if (response.status == 200) {
                localStorage.setItem('role', response.data.profile.role);
                localStorage.setItem('userId', response.data.profile.id);
                dispatch({
                    type: SET_PROFILE,
                    payload: response.data.profile
                });

                dispatch({
                    type: LOGIN,
                    payload: { login: true, error: '' }
                });
            } else {
                dispatch(check_response(response));
            }
        })
        .catch(err => {
            dispatch(check_response(err.response));
        });

}

export const check_login_status = () => async dispatch => {
    if (localStorage.usertoken) {
        get_user_info();
        dispatch({
            type: LOGIN,
            payload: { login: true, error: '' }
        });
    }
}
