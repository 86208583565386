import React, { useEffect, useState } from "react";
import { useDispatch, useSelector, useStore } from "react-redux";
import { useHistory } from "react-router-dom";
import Select from 'react-select';
import { showBookHead } from "../../actions/globalActions";
import { createProject, projectCreated } from "../../actions/projectActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import { getUsers } from "../../actions/userAction";
import { getCurrentDate } from "../../utils";

const AddBook = () => {
    let history = useHistory();
    const dispatch = useDispatch();

    const users = useSelector((state) => state.users.users);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.books.isCreated);
    const categories = useSelector(state => state.categories.categories);

    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [image, setImage] = useState("");
    const [designer, setDesigner] = useState("");
    const [reviewers, setReviewers] = useState(null);
    const [projectCoordinator, setProjectCoordinator] = useState("");
    const [academicHead, setAcademicHead] = useState("");
    const [phase, setPhase] = useState("");
    const [options, setOptions] = useState([]);
    const [category, setCategory] = useState("");

    useEffect(() => {
        dispatch(showBookHead(true));
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getUsers());
    }, []);

    useEffect(() => {
        const opts = [];
        users && users.map(user => {
            if (user.role === "reviewer") {
                opts.push({ value: user.id, label: user.name });
            }
        });
        setOptions(opts);
    }, [users]);

    useEffect(() => {
        if (isCreated) {
            dispatch(projectCreated());
            history.push("/books");
        }
    }, [isCreated]);

    const onReviewSelect = (selectedReviews) => {
        setReviewers(selectedReviews);
    }

    const submitForm = (e) => {
        e.preventDefault();

        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const reviwersData = [];
        reviewers.map((reviwer) => {
            reviwersData.push(reviwer.value);
        });
        const designersData = [];
        designersData.push(designer);

        const new_book = {
            bookName: name,
            bookDescription: description,
            designers: designersData,
            reviewers: reviwersData,
            projectCoordinator: projectCoordinator,
            academicHead: academicHead,
            categoryId: category
        };

        dispatch(createProject(new_book));
        //history.push("/books");
    };
    return (
        <div class="tab-pane fade show active" id="Project-add" role="tabpanel">
            <div className="row clearfix">
                <div className="col-lg-12">
                    <div className="card">
                        <div className="card-body">
                            <form onSubmit={submitForm}>
                                <div className="row clearfix">
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Name</label>
                                            <input type="text" required className="form-control" placeholder="Book Name"
                                                value={name} onChange={(e) => setName(e.target.value)} />
                                            <p className="error">{errors && errors.bookName}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Academic Head</label>
                                            <select class="form-control show-tick" required
                                                value={academicHead} onChange={(e) => setAcademicHead(e.target.value)}>
                                                <option value="">--Select Academic Head--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "academicHead") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors && errors.academicHead}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Designer</label>
                                            <select class="form-control show-tick"
                                                value={designer} onChange={(e) => setDesigner(e.target.value)} >
                                                <option value="">--Select Designer--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "designer") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors && errors.designer}</p>
                                        </div>
                                    </div>
                                    {localStorage.role == "superAdmin" && <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Coordinator</label>
                                            <select class="form-control show-tick" required
                                                value={projectCoordinator} onChange={(e) => setProjectCoordinator(e.target.value)}>
                                                <option value="">--Select Coordinator--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "projectCoordinator") {
                                                        return <option value={user.id}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                            <p className="error">{errors.projectCoordinator}</p>
                                        </div>
                                    </div>}
                                    {/* <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Reviewer</label>
                                            <select class="form-control show-tick"
                                                value={reviewer} onChange={(e) => setReviewer(e.target.value)} >
                                                <option value="">--Reviewer--</option>
                                                {users && users.map(user => {
                                                    if (user.role == "Reviewer") {
                                                        return <option value={user.name}>{user.name}</option>
                                                    }
                                                })}
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Reviewer</label>
                                            <Select value={reviewers} isMulti={true}
                                                options={options} onChange={onReviewSelect} />
                                            <p className="error">{errors && errors.reviwer}</p>
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Category</label>
                                            <select class="form-control show-tick" required
                                                value={category} onChange={(e) => setCategory(e.target.value)}>
                                                <option value="">--Book Category--</option>
                                                {categories.map(category =>
                                                    <option value={category.id}>{category.categoryName}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-4 col-md-12">
                                        <div className="form-group">
                                            <select class="form-control show-tick" required
                                                value={phase} onChange={(e) => setPhase(e.target.value)}>
                                                <option value="">--Project Phase--</option>
                                                <option value="Writing">Writing</option>
                                                <option value="Designing">Designing</option>
                                            </select>
                                        </div>
                                    </div> */}
                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label className="form-label">Book Description</label>
                                            <textarea type="text" required className="form-control" rows={4} placeholder="Book Description"
                                                value={description} onChange={(e) => setDescription(e.target.value)} />
                                            <p className="error">{errors && errors.bookDescription}</p>
                                        </div>
                                    </div>
                                    {/* <div className="col-lg-12">
                                        <input type="file" className="dropify" />
                                    </div> */}
                                    <div className="col-lg-12 mt-3">
                                        <button type="submit" className="btn btn-primary">Add</button>
                                        <button type="button" onClick={() => history.goBack()} className="btn btn-default float-right">Cancel</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default AddBook;