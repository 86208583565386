import { Route, Switch, useRouteMatch } from "react-router-dom";
import AddBook from "./AddBook";
import BookGraph from "./BookGraph";
import DetailBook from "./DetailBook";
import ListBooks from "./ListBooks";
import UpdateBook from "./UpdateBook";

const BookContent = () => {
    let match = useRouteMatch();
    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="tab-content">
                    <Switch>
                        <Route path={`${match.path}/add-book`}>
                            <AddBook />
                        </Route>
                        <Route path={`${match.path}/update-book/:id`}>
                            <UpdateBook />
                        </Route>
                        <Route path={`${match.path}/book-graph/:id`}>
                            <BookGraph />
                        </Route>
                        <Route path={`${match.path}/:bookId`}>
                            <DetailBook />
                        </Route>
                        <Route path={match.path}>
                            <ListBooks />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default BookContent;