import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setPageHeading, showBookHead } from "../../actions/globalActions";
import BookContent from "../books/BookContent";
import ProjectsHeader from "../books/ProjectsHeader"

const Books = () => {
    const dispatch = useDispatch();
    const bookHead = useSelector(state => state.globals.bookHead);

    useEffect(() => {
        dispatch(setPageHeading('Books'));
    })
    return ( 
        <>
            {bookHead && <ProjectsHeader /> }
            <BookContent />
        </>
     );
}
 
export default Books;