import {
  GET_USER,
  CREATE_USER,
  UPDATE_USER,
  DELETE_USER,
  GET_USERS,
  GET_ROLES,
  USER_ERRORS,
  EMPTY_ERRORS,
  USER_CREATED,
  SET_PROFILE,
  SET_USERS_FILTER,
} from "../actions/types";

const initialState = {
  users: [],
  user: null,
  profile: [],
  roles: [],
  errors: [],
  isCreated: false,
  filter: 'active'
};
export default (state = initialState, { type, payload }) => {
  
  switch (type) {
    case GET_USERS:
      return {
        ...state,
        users: payload
      }
    case CREATE_USER:
      return {
        ...state,
        users: [payload, ...state.users],
        isCreated: true
      };
    case GET_USER:
      return {
        ...state,
        user: payload,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user.id == payload.id ? payload : user
        ),
        isCreated: true
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user.id != payload),
      };
    case GET_ROLES:
      return {
        ...state,
        roles: payload
      }
    case USER_ERRORS:
      return {
        ...state,
        errors: payload
      }
    case EMPTY_ERRORS:
      return {
        ...state,
        errors: []
      }
    case USER_CREATED:
      return {
        ...state,
        isCreated: false
      }
    case SET_PROFILE:
      return {
        ...state,
        profile: payload
      }
    case SET_USERS_FILTER:
      return {
        ...state,
        filter: payload
      };
    default:
      return state;
  }
};