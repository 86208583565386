import { Link, useRouteMatch } from "react-router-dom";
import { date_time_to_date_format, server_url } from "../../utils";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const BookItem = ({ book }) => {
    let match = useRouteMatch();

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 200
        },

        title: {
            text: null
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                colors: ['orange', 'dodgerblue', 'gray', 'violet', 'slateblue', 'green']
            }
        },
        series: [{
            colorByPoint: true,
            data: book.pieChartData
        }]
    }

    return (
        <div className="col-lg-4 col-md-12">
            <div className="card">
                <div className="card-header">
                    <Link to={`${match.path}/${book.id}/chapter/${book.firstChapterId}`}><h3 className="card-title">{book.bookName}</h3></Link>
                    <div className="card-options">
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator' || localStorage.role == 'academicHead') && <Link style={{ 'color': '#0056b3' }} class="btn btn-sm btn-link" to={`${match.url}/book-graph/${book.id}`} data-toggle="tooltip" title="Graph"><i class="fa fa-area-chart"></i></Link>}
                        {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <Link style={{ 'color': '#0056b3' }} class="btn btn-sm btn-link" to={`${match.url}/update-book/${book.id}`} data-toggle="tooltip" title="Edit"><i class="fa fa-edit"></i></Link>}
                        {/* <a style={{'color': '#0056b3'}} class="btn btn-sm btn-link hidden-xs js-sweetalert" data-type="confirm" onClick={() => deleteBook1(book.id)} data-toggle="tooltip" title="Delete"><i class="fa fa-trash"></i></a> */}
                        {/* <label className="custom-switch m-0">
                            <input type="checkbox" defaultValue={1} className="custom-switch-input" defaultChecked />
                            <span className="custom-switch-indicator" />
                        </label>
                        <a href="#" className="card-options-collapse" data-toggle="card-collapse"><i className="fe fe-chevron-up" /></a> */}
                    </div>
                </div>
                <div className="card-body">
                    {/* <span className="tag tag-blue mb-3">Web Design</span> */}
                    {/* <p>{book.bookDescription}</p> */}
                    <div className="row">
                        <div className="col-5 py-1"><strong>Created:</strong></div>
                        <div className="col-7 py-1">{date_time_to_date_format(book.created_at)}</div>
                        {/* <div className="col-5 py-1"><strong>Project Coordinator:</strong></div>
                        <div className="col-7 py-1">{book.projectCoordinator}</div> */}
                        <div className="col-5 py-1"><strong>Academic Head:</strong></div>
                        <div className="col-7 py-1">
                            <img className="avatar avatar-sm" src={server_url + book.academicHeadImage} data-toggle="tooltip" title={book.academicHeadName} data-original-title="Avatar Name" />
                        </div>
                        <div className="col-5 py-1"><strong>Reviewer:</strong></div>
                        <div className="col-7 py-1">
                            {book.reviewers.map(reviewer => {
                                return <img key={reviewer.id} className="avatar avatar-sm" src={server_url + reviewer.reviewerImage} data-toggle="tooltip" title={reviewer.reviewerName} data-original-title="Avatar Name" />
                            })}
                        </div>
                        {/* <div className="col-5 py-1"><strong>Author:</strong></div>
                        <div className="col-7 py-1"><strong>{book.author}</strong></div> */}
                        <div className="col-5 py-1"><strong>Designer:</strong></div>
                        <div className="col-7 py-1">
                            {book.designers.map(designer => {
                                return <img key={designer.id} className="avatar avatar-sm" src={server_url + designer.designerImage} data-toggle="tooltip" title={designer.designerName} data-original-title="Avatar Name" />
                            })}
                        </div>
                        <div className="col-5 py-1"><strong>Phase:</strong></div>
                        <div className="col-7 py-1"><strong>{book.bookPhase}</strong></div>
                    </div>
                </div>
                <div className="card-footer">
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
                </div>
            </div>
        </div>

    );
}

export default BookItem;
