import {
    PROFILE_GET,
    PROFILE_UPDATE,
    PROFILE_CREATED,
    PROFILE_ERRORS,
    EMPTY_PROFILE_ERRORS,
    UPDATE_PROGRESS
} from "../actions/types";

const initialState = {
    profile: [],
    errors: [],
    isCreated: null,
    progress: 0
};
export default (state = initialState, { type, payload }) => {

    switch (type) {
        case PROFILE_GET:
            return {
                ...state,
                profile: payload
            };
        case PROFILE_UPDATE:
            return {
                ...state,
                profile: payload,
                isCreated: 'Your profile has been successfully updated.',
                errors: [],
                progress: 0
            };
        case PROFILE_CREATED:
            return {
                ...state,
                isCreated: false
            };
        case PROFILE_ERRORS:
            return {
                ...state,
                errors: payload,
                isCreated: null,
                progress: 0
            };
        case EMPTY_PROFILE_ERRORS:
            return {
                ...state,
                errors: [],
                isCreated: null
            };
        case UPDATE_PROGRESS:
            return {
                ...state,
                progress: payload
            }
        default:
            return state;
    }
}