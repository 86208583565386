import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { chapterCreated, createChapter, emptyChapterErrors, getChapters } from "../../actions/chapterActions";
import { getUsers } from "../../actions/userAction";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import Alert from '../elements/Alert';

const AddChapter = ({ bookIdd }) => {
    let history = useHistory();
    const dispatch = useDispatch();
    const users = useSelector((state) => state.users.users);
    const errors = useSelector(state => state.globals.errors);
    const isCreated = useSelector(state => state.chapters.isCreated);

    const [description, setDescription] = useState("");
    const [exercise, setExercise] = useState("");
    const [author, setAuthor] = useState("");
    const [title, setTitle] = useState("");

    useEffect(() => {
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getUsers());
    }, []);

    useEffect(() => {
        if (isCreated) {
            dispatch(chapterCreated());
            dispatch(getChapters(bookIdd));
            history.push(`/books/${bookIdd}`);
        }
    }, [isCreated]);

    const submitForm = (e) => {
        e.preventDefault();
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const new_chapter = {
            chapterName: title,
            chapterDescription: description,
            hasExercise: exercise,
            author: author,
            bookId: parseInt(bookIdd)
        };

        dispatch(createChapter(new_chapter));
    };
    return (
        <div className="col-lg-12">
            <div className="card">
                <div className="card-body">
                    <form onSubmit={submitForm}>
                        <div className="row clearfix">
                            <div className="col-md-12">
                                <Alert />
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Name</label>
                                    <input type="text" required className="form-control" placeholder="Chapter Name"
                                        value={title} onChange={(e) => setTitle(e.target.value)} />
                                    <p className="error">{errors && errors.chapterName}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Author</label>
                                    <select class="form-control show-tick" required
                                        value={author} onChange={(e) => setAuthor(e.target.value)}>
                                        <option value="">--Author--</option>
                                        {users && users.map(user => {
                                            if (user.role == "author") {
                                                return <option value={user.id}>{user.name}</option>
                                            }
                                        })}
                                    </select>
                                    <p className="error">{errors && errors.author}</p>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Contain Exercise?</label>
                                    <select class="form-control show-tick" required
                                        value={exercise} onChange={(e) => setExercise(e.target.value)}>
                                        <option value="">--Contain Exercise--</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                    </select>
                                    <p className="error">{errors && errors.hasExercise}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 col-md-12">
                                <div className="form-group">
                                    <label className="form-label">Chapter Description</label>
                                    <textarea type="text" className="form-control" rows={4} placeholder="Chapter Description"
                                        value={description} onChange={(e) => setDescription(e.target.value)} />
                                    <p className="error">{errors && errors.description}</p>
                                </div>
                            </div>
                            <div className="col-lg-12 mt-3">
                                <button type="submit" className="btn btn-primary">Add</button>
                                <button type="button" onClick={() => history.goBack()} className="btn btn-default float-right">Cancel</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    );
}

export default AddChapter;