// Login Types
export const LOGIN = "LOGIN";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";

// User Types
export const GET_USER = "GET_USER";
export const DELETE_USER = "DELETE_USER";
export const CREATE_USER = "CREATE_USER";
export const UPDATE_USER = "UPDATE_USER";
export const GET_USERS = "GET_USERS";
export const USER_ERRORS = "USER_ERRORS";
export const EMPTY_ERRORS = "EMPTY_ERRORS";
export const USER_CREATED = "USER_CREATED";
export const SET_USERS_FILTER = "SET_USERS_FILTER";

export const GET_ROLES = "GET_ROLES";

export const GET_AUTHORS = "GET_AUTHORS";
export const GET_DESIGNERS = "GET_DESIGNERS";
export const GET_ACADEMIC_HEADS = "GET_ACADEMIC_HEADS";
export const GET_BOOK_COORDINATORS = "GET_BOOK_COORDINATORS"; 
export const GET_REVIEWERS = "GET_REVIEWERS"; 

// Book Types
export const GET_PROJECT = "GET_PROJECT";
export const GET_PROJECT_GRAPH = "GET_PROJECT_GRAPH";
export const DELETE_PROJECT = "DELETE_PROJECT";
export const CREATE_PROJECT = "CREATE_PROJECT";
export const UPDATE_PROJECT = "UPDATE_PROJECT";
export const GET_PROJECTS = "GET_PROJECTS";
export const PROJECT_ERRORS = "PROJECT_ERRORS";
export const EMPTY_PROJECT_ERRORS = "EMPTY_PROJECT_ERRORS";
export const PROJECT_CREATED = "PROJECT_CREATED";
export const SET_PROJECT_FILTER = "SET_PROJECT_FILTER";

// Chapter Types
export const GET_CHAPTER = "GET_CHAPTER";
export const DELETE_CHAPTER = "DELETE_CHAPTER";
export const CREATE_CHAPTER = "CREATE_CHAPTER";
export const UPDATE_CHAPTER = "UPDATE_CHAPTER";
export const GET_CHAPTERS = "GET_CHAPTERS";
export const CHAPTER_ERRORS = "CHAPTER_ERRORS";
export const EMPTY_CHAPTER_ERRORS = "EMPTY_CHAPTER_ERRORS";
export const CHAPTER_CREATED = "CHAPTER_CREATED";
export const GET_CHAPTER_CONTENT = "GET_CHAPTER_CONTENT";
export const UPDATE_PROGRESS = "UPDATE_PROGRESS";
export const CONTENT_UPDATE = "CONTENT_UPDATE";
export const CONTENT_ERRORS = "CONTENT_ERRORS";
export const UPDATE_CHAPTER_PROGRESS = "UPDATE_CHAPTER_PROGRESS";
export const GET_PENDING_TASKS = "GET_PENDING_TASKS";
export const GET_ADMIN_PENDING_TASKS = "GET_ADMIN_PENDING_TASKS";
export const GET_PIE_CHART_DATA = "GET_PIE_CHART_DATA";
export const GET_CHAPTER_COMMENTS = "GET_CHAPTER_COMMENTS";
export const EMPTY_PENDING_TASKS = "EMPTY_PENDING_TASKS";
export const FILE_NAME = "FILE_NAME";

// Profile Types
export const SET_PROFILE = "SET_PROFILE";
export const PROFILE_GET = "PROFILE_GET";
export const PROFILE_DELETE = "PROFILE_DELETE";
export const PROFILE_CREATE = "PROFILE_CREATE";
export const PROFILE_UPDATE = "PROFILE_UPDATE";
export const PROFILES_GET = "PROFILES_GET";
export const PROFILE_CREATED = "PROFILE_CREATED";
export const PROFILE_ERRORS = "PROFILE_ERRORS";
export const EMPTY_PROFILE_ERRORS = "EMPTY_PROFILE_ERRORS";

// Comment Types
export const GET_COMMENT = "GET_COMMENT";
export const DELETE_COMMENT = "DELETE_COMMENT";
export const CREATE_COMMENT = "CREATE_COMMENT";
export const UPDATE_COMMENT = "UPDATE_COMMENT";
export const GET_COMMENTS = "GET_COMMENTS";
export const COMMENT_ERRORS = "COMMENT_ERRORS";
export const EMPTY_COMMENT_ERRORS = "EMPTY_COMMENT_ERRORS";
export const COMMENT_CREATED = "COMMENT_CREATED";

// Categories Types
export const GET_CATEGORY = "GET_CATEGORY";
export const DELETE_CATEGORY = "DELETE_CATEGORY";
export const CREATE_CATEGORY = "CREATE_CATEGORY";
export const UPDATE_CATEGORY = "UPDATE_CATEGORY";
export const GET_CATEGORIES = "GET_CATEGORIES";
export const CATEGORY_ERRORS = "CATEGORY_ERRORS";
export const EMPTY_CATEGORY_ERRORS = "EMPTY_CATEGORY_ERRORS";
export const CATEGORY_CREATED = "CATEGORY_CREATED";

// global Types
export const SET_PAGE_HEADING = "SET_PAGE_HEADING";
export const SHOW_BOOK_HEADING = "SHOW_BOOK_HEADING";
export const SERVER_ERROR = "SERVER_ERROR";
export const ALERT_MESSAGE = "ALERT_MESSAGE";
export const ALERT_MESSAGE_EMPTY = "ALERT_MESSAGE_EMPTY";
export const ERRORS_GET = "ERRORS_GET";
export const ERRORS_EMPTY = "ERRORS_EMPTY";