import { Route, Switch, useRouteMatch } from "react-router";
import CommentsAdd from "./CommentsAdd";
import CommentsList from "./CommentsList";
import CommentsUpdate from "./CommentsUpdate";

const CommentsContent = () => {
    let match = useRouteMatch();
    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="tab-content">
                    <Switch>
                        <Route path={`${match.path}/add-label`}>
                            <CommentsAdd />
                        </Route>
                        <Route path={`${match.path}/update-label/:id`}>
                            <CommentsUpdate />
                        </Route>
                        <Route path={match.path}>
                            <CommentsList />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}
 
export default CommentsContent;