import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getRoles } from "../../actions/userAction";
import AddUser from "./AddUser"
import ListUsers from "./ListUsers";
import UpdateUser from "./UpdateUser";

const ContentSection = () => {
    const dispatch = useDispatch();
    let match = useRouteMatch();
    useEffect(() => {
        dispatch(getRoles());
    }, [])
    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="tab-content">
                    <Switch>
                        <Route path={`${match.path}/add-user`}>
                            <AddUser />
                        </Route>
                        <Route path={`${match.path}/update-user/:id`}>
                            <UpdateUser />
                        </Route>
                        <Route path={match.path}>
                            <ListUsers />
                        </Route>
                    </Switch>
                </div>
            </div>
        </div>
    );
}

export default ContentSection;