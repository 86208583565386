import {
  GET_CHAPTER,
  CREATE_CHAPTER,
  UPDATE_CHAPTER,
  DELETE_CHAPTER,
  GET_CHAPTERS,
  CHAPTER_CREATED,
  CHAPTER_ERRORS,
  EMPTY_CHAPTER_ERRORS,
  GET_CHAPTER_CONTENT,
  CONTENT_ERRORS,
  CONTENT_UPDATE,
  UPDATE_CHAPTER_PROGRESS,
  GET_PENDING_TASKS,
  GET_ADMIN_PENDING_TASKS,
  GET_PIE_CHART_DATA,
  GET_CHAPTER_COMMENTS,
  EMPTY_PENDING_TASKS
} from "../actions/types";

const initialState = {
  chapters: [],
  chapter: null,
  errors: [],
  isCreated: false,
  chapterContent: [],
  progress: 0,
  pendingTasks: [],
  adminPendingTasks: [],
  pieChartData: [],
  chapterComments: []
};
export default (state = initialState, { type, payload }) => {
  // console.log('progress', type, payload);
  switch (type) {
    case GET_CHAPTERS:
      return {
        ...state,
        chapters: payload
      }
    case CREATE_CHAPTER:
      return {
        ...state,
        chapters: [payload, ...state.chapters],
        isCreated: true
      };
    case GET_CHAPTER:
      return {
        ...state,
        chapter: payload,
      };
    case UPDATE_CHAPTER:
      return {
        ...state,
        chapters: state.chapters.map((chapter) =>
          chapter.id == payload.id ? payload : chapter),
        isCreated: true
      };
    case DELETE_CHAPTER:
      return {
        ...state,
        chapters: state.chapters.filter((chapter) => chapter.id != payload),
      };
    case CHAPTER_CREATED:
      return {
        ...state,
        isCreated: false
      };
    case CHAPTER_ERRORS:
      return {
        ...state,
        errors: payload
      };
    case EMPTY_CHAPTER_ERRORS:
      return {
        ...state,
        errors: []
      };
    case GET_CHAPTER_CONTENT:
      return {
        ...state,
        chapterContent: payload
      };
    case UPDATE_CHAPTER_PROGRESS:
      return {
        ...state,
        progress: payload
      };
    case CONTENT_UPDATE:
      return {
        ...state,
        chapterContent: payload,
        progress: 0
      };
    case CONTENT_ERRORS:
      return {
        ...state,
        errors: payload,
        progress: 0
      }
    case GET_PENDING_TASKS:
      return {
        ...state,
        pendingTasks: payload
      }
    case GET_ADMIN_PENDING_TASKS:
      return {
        ...state,
        adminPendingTasks: payload
      }
    case GET_PIE_CHART_DATA: {
      return {
        ...state,
        pieChartData: payload
      }

    }
    case GET_CHAPTER_COMMENTS: {
      return {
        ...state,
        chapterComments: payload
      }
    }
    case EMPTY_PENDING_TASKS: {
      return {
        ...state,
        adminPendingTasks: payload,
        pendingTasks: payload
      }
    }
    default:
      return state;
  }
};