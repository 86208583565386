import { useState } from "react";
import { Link, useRouteMatch } from "react-router-dom";
import { date_time_to_date_format } from "../../utils";

const BookDetailItem = ({ chapter }) => {
    let match = useRouteMatch();
    const [phases, setPhases] = useState({
        'writing': <><input type="checkbox" checked={false} title="writing" /><input type="checkbox" checked={false} title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'design_guidelines': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked={false} title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'line_art': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'drawing': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'completed': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked title="Line Art" /><input type="checkbox" checked title="Drawing" /></>,
        'type_setting': <><input type="checkbox" checked={false} /><input type="checkbox" checked={false} /></>,
        'full_book': <><input type="checkbox" checked /><input type="checkbox" checked={false} /></>,
        'full_book_complete': <><input type="checkbox" checked /><input type="checkbox" checked /></>
    });
    return (
        <tr>
            <td><Link to={`${match.url}/chapter/${chapter.id}`}>{chapter.isPending == 1 && <i class="fa fa-circle mr-1 text-red" aria-hidden="true"></i>}{chapter.chapterName}</Link></td>
            {/* <td><span>Pakistan</span></td> */}
            <td><span className="tag tag-default progress">{phases[chapter.chapterUnder]}</span></td>

            <td><span className="tag tag-default">{chapter.authorName}</span></td>
            <td><span>{chapter.chapterUnder}</span></td>
            <td><span>{date_time_to_date_format(chapter.created_at)}</span></td>
            <td><span>{chapter.chapterDescription}</span></td>
            <td class="text-right">
            {chapter.exerciseChapterId == 0 && (localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <Link class="btn btn-sm btn-link" to={`${match.url}/update-chapter/${chapter.id}`} data-toggle="tooltip" title="Edit"><i class="fa fa-edit"></i></Link>}
                {/* <a class="btn btn-sm btn-link hidden-xs js-sweetalert" data-type="confirm" onClick={() => deleteChap(chapter.id)} data-toggle="tooltip" title="Delete"><i class="fa fa-trash"></i></a> */}
            </td>
        </tr>
    );
}

export default BookDetailItem;