import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ProgressBar from "@ramonak/react-progress-bar";
import { Link, useParams, useRouteMatch } from "react-router-dom";
import {
    addChapterContentReview, getChapterContent,
    updateChapterContent, addChapterDesignGuidlines,
    moveChapterToLineart, addchapterComment,
    getchapterComments, deleteCommentAction, addChapterFileAction
} from "../../actions/chapterActions";

import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

import { base_url, base_url_file, date_time_to_date_format, server_url } from "../../utils";
import { getComments } from "../../actions/commentActions";
import { emptyAlertMessage, emptyErrors } from "../../actions/globalActions";
import uuid from 'react-uuid';
import Modal from 'react-modal';
import { getRoles } from "../../actions/userAction";
import NewChapterDetail from "./NewChapterDetail";

const ChapterDetail = ({ book, chapters, pieChartData }) => {
    let match = useRouteMatch();
    const dispatch = useDispatch();
    let { chapId } = useParams();
    const errors = useSelector(state => state.globals.errors);
    const chapterContent = useSelector(state => state.chapters.chapterContent);
    const progress = useSelector(state => state.chapters.progress);
    const chapterComments = useSelector(state => state.chapters.chapterComments);
    const labels = useSelector(state => state.comments.comments);
    const roles = useSelector(state => state.users.roles);
    const fileName = useSelector(state => state.globals.fileName);

    const [chapterText, setChapterText] = useState("");
    const [comment, setComment] = useState("");
    const [notes, setNotes] = useState("");
    const [chapterFile, setChapterFile] = useState("");
    const [exerciseFile, setExerciseFile] = useState("");
    const [currentChapter, setCurrentChapter] = useState([]);
    const [chapterValue, setChapterValue] = useState("");
    const [exerciseValue, setExerciseValue] = useState("");
    const [isApproved, setIsApproved] = useState("accept");
    const [revNotes, setRevNotes] = useState("");
    const [reviewFile, setReviewFile] = useState("");
    const [labelId, setLabelId] = useState("");
    const [reviewValue, setReviewValue] = useState("");
    const [dgFile, setDgFile] = useState("");
    const [dgValue, setDgValue] = useState("");
    const [btnStatus, setBtnStatus] = useState(false);
    const [designGuidelinesText, setDesignGuidelinesText] = useState("");
    const [reviewerFileName, setReviewerFileName] = useState("");
    const [logsCount, setLogsCount] = useState(0);
    const [phases, setPhases] = useState({
        'writing': <><input type="checkbox" checked={false} title="writing" /><input type="checkbox" checked={false} title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'design_guidelines': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked={false} title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'line_art': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked={false} title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'drawing': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked title="Line Art" /><input type="checkbox" checked={false} title="Drawing" /></>,
        'completed': <><input type="checkbox" checked title="writing" /><input type="checkbox" checked title="Design Guideline" /><input type="checkbox" checked title="Line Art" /><input type="checkbox" checked title="Drawing" /></>,
        'type_setting': <><input type="checkbox" checked={false} /><input type="checkbox" checked={false} /></>,
        'full_book': <><input type="checkbox" checked /><input type="checkbox" checked={false} /></>,
        'full_book_complete': <><input type="checkbox" checked /><input type="checkbox" checked /></>
    });
    const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
    const [modalIsOpen, setIsOpen] = useState(false);
    const [modalFileUrl, setFileUrl] = useState("");
    const [downloadFilename, setDownloadFilename] = useState("");
    const [chapIddd, setChapIddd] = useState("");
    const [fullBook, setFullBook] = useState([]);
    const [showMap, setShowMap] = useState(true);
    const [designGuidelinesModal, setDesignGuidelinesModal] = useState(false);
    const [designGuidelinesArray, setDesignGuidelinesArray] = useState([]);
    const [index, setIndex] = useState(0);
    const [addDG, setAdDG] = useState(false);
    const [showFile, setShowFile] = useState(false);
    const [showImageModal, setShowImageModal] = useState(false);
    const [fileURL, setFileURL] = useState(null);

    const [labelColor, setLabelColor] = useState();
    const [deleteCommentId, setDeleteCommentId] = useState();

    const [originalText, setOriginalText] = useState("");
    const [suggestionText, setSuggestionText] = useState("");

    const [viewCommentModal, setViewCommentModal] = useState(false);
    const [contentModal, setContentModal] = useState(false);
    const [reviewModal, setReviewModal] = useState(false);

    const [modalComments, setModalComments] = useState([]);
    const [startScroll, setStartScroll] = useState(0);
    const [endScroll, setEndScroll] = useState(2);

    let reviewCount = 1, reviewId = 0, reviewCount1 = 0;

    useEffect(() => {
        // updateCurrentChapter(chapId);
        chapIddd ? updateCurrentChapter(chapIddd) : updateCurrentChapter(chapId);

        if (chapters.length > 0) {
            setFullBook(chapters.filter(chapterr => chapterr.isFullBook == 1));
        }
    }, [chapters]);

    useEffect(() => {
        if (fileName) {
            setChapterValue("")
            const dgArray = designGuidelinesArray;
            dgArray[index].file = fileName;


            setBtnStatus(true);

            setDesignGuidelinesModal(false);

            dispatch(emptyErrors());
            dispatch(emptyAlertMessage());

            const data = new FormData()
            data.append('id', currentChapter.id);
            data.append('designGuidelines', JSON.stringify(dgArray));
            data.append('status', 'not_submited');
            dispatch(updateChapterContent(data, book.id));

        }
    }, [fileName]);

    useEffect(() => {
        dispatch(getRoles());
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        dispatch(getComments());
    }, []);

    useEffect(() => {
        setBtnStatus(false);
        chapterContentReceived();
        console.log('chapter content......');
        // if (chapterContent?.writingContent?.designGuidelines) {
        //     setDesignGuidelinesArray(chapterContent?.writingContent?.designGuidelines?.split(","));
        // }
        if (localStorage.role == 'designer') {
            if (chapterContent?.currentContent?.designGuidelines) {
                setDesignGuidelinesArray(JSON.parse(chapterContent?.currentContent?.designGuidelines));
            } else if (chapterContent?.writingContent?.designGuidelines) {
                setDesignGuidelinesArray(JSON.parse(chapterContent?.writingContent?.designGuidelines));
            }
        } else {
            if (chapterContent?.currentContent?.designGuidelines) {
                setDesignGuidelinesArray(JSON.parse(chapterContent?.currentContent?.designGuidelines));
            } else {
                chapterContent && chapterContent.currentContent && setDesignGuidelinesText(chapterContent.currentContent.designGuidelinesText);
            }
        }
        window.getSelection().removeAllRanges();

        if (chapterContent?.contentHistory?.length > 0) {
            let iddd = 0, countt = 0;
            for (let i = 1; i < chapterContent.contentHistory.length; i++) {
                if (iddd != chapterContent.contentHistory[i].id) {
                    iddd = chapterContent.contentHistory[i].id;
                    countt++;
                }
            }
            setLogsCount(countt);
        }
        chapterContent && chapterContent.currentContent && chapterContent.currentContent.id && dispatch(getchapterComments(chapterContent.currentContent.id));
        setStartScroll(0);
        setEndScroll(2);
    }, [chapterContent]);

    useEffect(() => {
        setBtnStatus(false);
    }, [errors]);

    const chapterContentReceived = () => {
        console.log('chapterContentReceived......');
        setReviewFile("");
        setReviewValue("")
        setLabelId("");
        setRevNotes("");
        setIsApproved("accept");
        setReviewerFileName("");
    }

    const updateCurrentChapter = (idd) => {
        setCurrentChapter(chapters.find(({ id }) => id == idd));
        dispatch(getChapterContent(idd));
        setNotes("");
        setChapterValue("");
        setExerciseValue("");
        setDgValue("");
        setDesignGuidelinesText("");
        setReviewerFileName("");
    }

    const changeChapId = (chapIdd) => {
        setDesignGuidelinesArray([]);
        setChapIddd(chapIdd);
        updateCurrentChapter(chapIdd);
        setChapterText("");
    }

    const submitForm = (e) => {
        e.preventDefault();
        setBtnStatus(true);
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const data = new FormData();

        chapterFile && data.append('chapterFile', chapterFile, chapterFile.name);
        exerciseFile && data.append('exerciseFile', exerciseFile, exerciseFile.name);
        data.append('chapterText', chapterText);
        data.append('id', currentChapter.id);
        notes && data.append('comments', notes);

        dispatch(updateChapterContent(data, book.id));
    };

    const addChapterFile = (file) => {
        // e.target.files[0]); setReviewValue(e.target.value
        setAdDG(false);
        setBtnStatus(true);
        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());
        const data = new FormData()
        data.append('id', currentChapter.id);
        file && data.append('chapterFile', file.files[0], file.value);
        dispatch(addChapterFileAction(data));
    }

    const onRevSubmit = (e) => {
        e.preventDefault();
        setBtnStatus(true);

        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const data = new FormData()
        data.append('id', currentChapter.id);
        data.append('reviewFileName', reviewerFileName);
        if (isApproved == 'reject') {
            reviewFile && data.append('reviewFile', reviewFile, reviewFile.name);
            labelId && data.append('labelId', labelId);
        }

        revNotes && data.append('comments', revNotes);

        if (localStorage.role != 'academicHead') {
            data.append('reviewStatus', 'accept');
        } else {
            data.append('reviewStatus', isApproved);
        }

        console.log('chapter content', currentChapter);

        dispatch(addChapterContentReview(data, book.id));
    };

    const deleteDesignGuideline = () => {
        const designArray = designGuidelinesArray;
        // console.log('index', index,  );
        // console.log('updated', designArray);
        setBtnStatus(true);
        setDesignGuidelinesModal(false);

        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const data = new FormData()
        data.append('id', currentChapter.id);

        data.append('designGuidelines', JSON.stringify(designArray.splice(index, 1)));

        data.append('designGuidelinesText', 1);
        dispatch(addChapterDesignGuidlines(data, book.id));
        setDesignGuidelinesText("");

    }

    const onDesignGuidelinesSubmit = (e) => {
        e.preventDefault();
        setBtnStatus(true);

        // setDesignGuidelinesArray([...designGuidelinesArray, designGuidelinesText]);
        setDesignGuidelinesModal(false);
        // setDesignGuidelinesText("");

        dispatch(emptyErrors());
        dispatch(emptyAlertMessage());

        const data = new FormData()
        data.append('id', currentChapter.id);
        if (index == -1) {
            data.append('designGuidelines', JSON.stringify([...designGuidelinesArray, { text: designGuidelinesText, file: null }]));
        } else {
            const designArray = designGuidelinesArray;
            designArray[index].text = designGuidelinesText;
            data.append('designGuidelines', JSON.stringify(designArray));
        }

        data.append('designGuidelinesText', 1);
        // data.append('designGuidelines', dgFile, dgFile.name);
        console.log(data, 'guidlines add');
        dispatch(addChapterDesignGuidlines(data, book.id));
        setDesignGuidelinesText("");

    }

    const fileDownload = () => {
        let a = document.createElement('a');
        a.href = modalFileUrl;
        a.download = downloadFilename[downloadFilename.length - 2] + "-" + downloadFilename[downloadFilename.length - 1];
        a.click();
    }

    const downloadFile = (url, fileName) => {
        document.body.style.cursor = 'wait';
        fetch(url + "?fileName=" + fileName, {
            method: 'POST',
            headers: { 'Content-Type': 'application/json', Authorization: `Bearer ${localStorage.usertoken}` },
        }).then(response => {
            setDownloadFilename(fileName.split('/'))
            response.blob().then(blob => {
                var fileURL = URL.createObjectURL(blob);
                setFileUrl(fileURL);
                openModal();
                setTimeout(function () {
                    document.getElementById("fileUrlIframe").contentWindow.document.head.insertAdjacentHTML("beforeend", '<style>body * { width: 100%;} @import url(http://www.localhost:3000/assets/fonts/jameel-noori-nastaleeq-kasheeda.ttf) format("truetype");* { font-family: JNNK; }</style>');
                    document.getElementById("fileUrlIframe").contentDocument.body.style.fontFamily = 'JNNK';
                }, 1000);


                // var win = window.open('?','_self');
                // win.document.write('<iframe src="' + fileURL + '" frameborder="0" style="border:0; top:0px; left:0px; bottom:0px; right:0px; width:100%; height:100%;" allowfullscreen></iframe>')
                // let url = window.URL.createObjectURL(blob);
                // let a = document.createElement('a');
                // a.href = url;
                // a.download = downloadFilename[downloadFilename.length - 2] + "-" + downloadFilename[downloadFilename.length - 1];
                // a.click();
                document.body.style.cursor = 'default';
            });
        });
    }

    function openModal() {
        setIsOpen(true);
        // let app = document.querySelector('#fileUrlIframe').contentWindow.document.head;
        // app.append('<style>body * { width: 100%; }</style>');
    }

    function afterOpenModal() {
        // document.getElementById("fileUrlIframe").contentWindow.document.head.insertAdjacentHTML("beforeend", '<style>body * { width: 100%; }</style>')
    }

    function closeModal() {
        setIsOpen(false);
        setFileUrl('');
    }

    const afterOpenContentModal = () => {

    }

    const closeContentModal = () => {
        setContentModal(false);
    }

    const afterOpenreviewModal = () => {
        emptyCommentModal();
    }

    const closeReviewModal = () => {
        setReviewModal(false);
    }

    const onMoveToLineArtButton = () => {
        dispatch(moveChapterToLineart(currentChapter.id, book.id));
    }

    const showCommentModal = (chap_comment) => {
        // const chap_comment = chapterComments.filter(chap_comm => chap_comment_id == chap_comm.id)[0];
        setOriginalText(chap_comment.originalText);
        setSuggestionText(chap_comment.suggestionText);
        setLabelColor(chap_comment.labelColor);
        setLabelId(chap_comment.labelId);
        //         labelId: 2
        // originalText: "text1"
        // suggestionText: "text2"
        console.log(chap_comment);
        setViewCommentModal(true);
    }

    const afterOpenViewCommentModal = () => {

    }

    const closeViewCommentModal = () => {
        setViewCommentModal(false);
    }


    const endoreReview = (chapterId, reviewer, e) => {
        // document.getElementById(e.target.id).classList.remove('notdisabled');
        const newComments = revNotes + '\n' + reviewer.comments;

        setRevNotes(newComments);
        setLabelId(reviewer.labelId);
        // setReviewerFileName(reviewer.reviewFile);
        setIsApproved('reject');
        // const data = new FormData()
        // data.append('id', chapterId);
        // data.append('reviewFileName', reviewer.reviewFile);
        // data.append('labelId', reviewer.labelId);
        // data.append('comments', reviewer.comments);
        // data.append('reviewStatus', 'reject');
        // data.append('endorse', 1);

        // dispatch(addChapterContentReview(data));
    }

    const emptyCommentModal = () => {
        setOriginalText("");
        setSuggestionText("");
        setLabelId("");
    }

    const deleteComment = (chap_id) => {
        dispatch(deleteCommentAction(chap_id));
        setDeleteConfirmModal(false);
    }

    const addComment = (e) => {
        e.preventDefault();

        const commentData = {
            originalText,
            suggestionText,
            labelId,
            id: chapterContent.currentContent.id
        }
        // setModalComments([...modalComments, commentData]);
        dispatch(addchapterComment(commentData));
        closeReviewModal();
        emptyCommentModal();
    }

    const options = {
        chart: {
            plotBackgroundColor: null,
            plotBorderWidth: null,
            plotShadow: false,
            type: 'pie',
            height: 200
        },
        title: {
            text: null
        },
        tooltip: {
            pointFormat: '<b>{point.percentage:.1f}%</b>'
        },
        accessibility: {
            point: {
                valueSuffix: '%'
            }
        },
        plotOptions: {
            pie: {
                allowPointSelect: true,
                cursor: 'pointer',
                dataLabels: {
                    enabled: true,
                    format: '<b>{point.name}</b>: {point.percentage:.1f} %'
                },
                colors: ['orange', 'dodgerblue', 'gray', 'violet', 'slateblue', 'green']
            }
        },
        series: [{
            colorByPoint: true,
            data: pieChartData
        }]
    }

    return (
        <> 
            <div className="col-lg-12 ml-31">
                <div className="container-fluid">
                    <div className="row clearfix">
                        <div className="col-lg-6 col-md-12">
                            <div className="input-group mr-2" style={{}}>
                                <button className="btn-hallow mr-2" style={{ borderRadius: '0.25rem' }}
                                    onClick={() => { setShowMap(!showMap) }}> Graph
                                </button>
                            </div>
                            <div className="row" style={{ display: showMap ? 'block' : 'none' }}>
                                <div className="col-12">
                                    <HighchartsReact
                                        highcharts={Highcharts}
                                        options={options}
                                    />
                                </div>
                            </div>
                            <div className="col-12">
                                {fullBook.length > 0 && <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{book && book.bookName} (Full Book)</h3>
                                    </div>

                                    <div className="table-responsive" style={{ maxHeight: '80vh' }}>
                                        <table className="table table-hover table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Book</th>
                                                    <th>Progress</th>
                                                    <th>Phase</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td><Link className={fullBook[0].id == currentChapter?.id ? "selected_book" : ""} onClick={() => changeChapId(fullBook[0].id)} to={`${match.url}/../../chapter/${fullBook[0].id}`}>{fullBook[0].isPending == 1 && <i class="fa fa-circle mr-1 text-red" aria-hidden="true"></i>}{fullBook[0].chapterName}</Link></td>
                                                    <td><span className="tag tag-default progress">{phases[fullBook[0].chapterUnder]}</span></td>
                                                    {/* <td><span>{chapter.hasExercise ? 'Yes' : 'No'}</span></td> */}
                                                    <td><span>{fullBook[0].chapterUnder}</span></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>}
                                <div className="card">
                                    <div className="card-header">
                                        <h3 className="card-title">{book && book.bookName} (Chapters)</h3>
                                    </div>
                                    <div className="table-responsive" style={{ maxHeight: '80vh' }}>
                                        <table className="table table-hover table-striped table-vcenter mb-0 text-nowrap">
                                            <thead>
                                                <tr>
                                                    <th>Chapter</th>
                                                    <th>Progress</th>
                                                    {/* <th>Exercise</th> */}
                                                    <th>Phase</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {chapters && chapters.map(chapter => {

                                                    if (chapter.isFullBook != 1) {
                                                        return <tr>
                                                            <td style={{ maxWidth: '100px', overflow: 'hidden' }}><Link className={chapter.id == currentChapter?.id ? "selected_book" : ""} onClick={() => changeChapId(chapter.id)} to={`${match.url}/../../chapter/${chapter.id}`} title={chapter.chapterName}>{chapter.isPending == 1 && <i class="fa fa-circle mr-1 text-red" aria-hidden="true"></i>}{chapter.chapterName}</Link></td>
                                                            <td><span className="tag tag-default progress">{phases[chapter.chapterUnder]}</span></td>
                                                            {/* <td><span>{chapter.hasExercise ? 'Yes' : 'No'}</span></td> */}
                                                            <td><span>{chapter.chapterUnder}</span></td>
                                                        </tr>
                                                    }
                                                })}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="col-lg-6 col-md-12">
                            {chapterContent && (chapterContent.currentContent || chapterContent.canSubmit || chapterContent.canReview || chapterContent.canUploadDesignGuidelines) && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">{currentChapter && currentChapter.chapterName}

                                    </h3>
                                    <div class="card-options ees">
                                        Phase: {currentChapter && currentChapter.chapterUnder}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div className="col-lg-12 col-md-12">
                                        {(chapterContent && chapterContent.canSubmit) ? (<form onSubmit={submitForm}>
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    {currentChapter && currentChapter.isFullBook == 1 ? <label className="form-label">Add {chapterContent.inputText}</label> : <label className="form-label">Add {chapterContent.inputText}</label>}
                                                    {currentChapter && currentChapter.chapterUnder == "writing" ?

                                                        <textarea type="text" className="form-control" rows={4} placeholder="Add Content"
                                                            value={chapterText} onClick={() => setContentModal(true)} required />

                                                        : <>
                                                            {designGuidelinesArray?.length && <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                                onClick={() => {
                                                                    startScroll > 0 && setEndScroll(endScroll - 1);
                                                                    startScroll > 0 && setStartScroll(startScroll - 1)
                                                                }}>
                                                                <img src="/assets/images/left-solid.svg" width="30px" /></button>}
                                                            {designGuidelinesArray?.map((dg, key) => {
                                                                return (key >= startScroll && key < endScroll) && <button type="button" className="btn btn-warning m-1"
                                                                    onClick={() => { setDesignGuidelinesModal(true); setAdDG(true); setShowFile(dg.file); setIndex(key); setDesignGuidelinesText(dg.text) }}>Design Guideline {key + 1}{dg.file ? <input className="ml-1" type="checkbox" checked /> : ''}</button>
                                                            })}
                                                            {designGuidelinesArray?.length && <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                                onClick={() => {
                                                                    endScroll < designGuidelinesArray?.length && setEndScroll(endScroll + 1);
                                                                    endScroll < designGuidelinesArray?.length && setStartScroll(startScroll + 1)
                                                                }}>
                                                                <img src="/assets/images/right-solid.svg" width="30px" /></button>}</>}

                                                    {/* designGuidelinesArray.map((dg, key) => {

                                                        })} */}

                                                    {/* // <input type="file" className="form-control" required
                                                    //     value={chapterValue} onChange={(e) => { setChapterFile(e.target.files[0]); setChapterValue(e.target.value) }} />} */}

                                                    {currentChapter && currentChapter.chapterUnder == "writing" ?
                                                        <p className="error">{errors.chapterText}</p>
                                                        :
                                                        <p className="error">{errors.chapterFile}</p>}
                                                </div>
                                            </div>
                                            {currentChapter && currentChapter.isFullBook == 0 && currentChapter.hasExercise == 1 && chapterContent.canUploadExercise == 1 && <div className="col-lg-6 col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Add Exercise</label>
                                                    <input type="file" className="form-control"
                                                        value={exerciseValue} onChange={(e) => { setExerciseFile(e.target.files[0]); setExerciseValue(e.target.value) }} />
                                                    <p className="error">{errors.exerciseFile}</p>
                                                </div>
                                            </div>}
                                            <div className="col-md-12">
                                                <div className="form-group">
                                                    <label className="form-label">Add Notes</label>
                                                    <textarea type="text" className="form-control" rows={4} placeholder="Add Notes"
                                                        value={notes} onChange={(e) => setNotes(e.target.value)} />
                                                    <p className="error">{errors.notes}</p>
                                                </div>
                                            </div>
                                            {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />}

                                            <div className="col-lg-12 mt-3">
                                                {(currentChapter?.chapterUnder == 'line_art' || currentChapter?.chapterUnder == 'drawing') ?
                                                    (!designGuidelinesArray.find(dg => dg.file == null) && <button type="submit" disabled={btnStatus} className="btn btn-primary">Submit</button>) :
                                                    <button type="submit" disabled={btnStatus} className="btn btn-primary">Submit</button>}
                                            </div>
                                        </form>) : ((chapterContent && chapterContent.currentContent) && <div>
                                            <a onClick={() => downloadFile(base_url_file, chapterContent.currentContent.chapterFile)} href="javascript:void(0);" >View File</a>
                                            <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.currentContent.created_at)}</p>
                                            {currentChapter && currentChapter.hasExercise == 1 && chapterContent.currentContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.currentContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}

                                            <p><br /><strong>Notes: </strong> <br /> <span className="class-comments">{chapterContent && chapterContent.currentContent.comments}</span></p>
                                            {/* {chapterContent.currentContent.designGuidelinesText && */}
                                            {chapterContent.currentContent.designGuidelines ? <p><strong>Design Guidelines: </strong><br />
                                                <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                    onClick={() => {
                                                        startScroll > 0 && setEndScroll(endScroll - 1);
                                                        startScroll > 0 && setStartScroll(startScroll - 1)
                                                    }}>
                                                    <img src="/assets/images/left-solid.svg" width="30px" /></button>
                                                {designGuidelinesArray?.map((dg, key) => {
                                                    return (key >= startScroll && key < endScroll) && <button type="button" className="btn btn-warning m-1"
                                                        onClick={() => { setDesignGuidelinesModal(true); setModalComments(chapterComments); setIndex(key); setOriginalText(key + 1); setShowFile(dg.file); setDesignGuidelinesText(dg.text) }}>Design Guideline {key + 1}</button>
                                                })}
                                                <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                    onClick={() => {
                                                        endScroll < designGuidelinesArray?.length && setEndScroll(endScroll + 1);
                                                        endScroll < designGuidelinesArray?.length && setStartScroll(startScroll + 1)
                                                    }}>
                                                    <img src="/assets/images/right-solid.svg" width="30px" /></button>
                                            </p> : chapterContent.currentContent.designGuidelinesText && <p><strong>Design Guidelines: </strong> <br /> <span className="class-comments urdu-text">{chapterContent.currentContent.designGuidelinesText}</span></p>}
                                            {/* <p><span className="class-comments urdu-text">{chapterContent.currentContent.designGuidelinesText}</span></p>} */}
                                            {chapterContent.currentContent.showMoveToLineArtButton === 1 && <input type="button" onClick={() => onMoveToLineArtButton()} class="btn btn-primary mr-2" value="Move to Line Art"></input>}
                                            {/* {localStorage.role == "reviewer"} */}
                                            {chapterContent.currentContent.canReview == 1 && <form onSubmit={onRevSubmit}>
                                                <div className="col-lg-4 col-md-12">
                                                    <div className="form-group">
                                                        <label className="form-label">Chapter Status</label>
                                                        <select class="form-control show-tick"
                                                            value={isApproved} onChange={(e) => setIsApproved(e.target.value)}>
                                                            <option value="accept">Approve</option>
                                                            <option value="reject">Reject</option>
                                                        </select>
                                                        <p className="error">{errors.isApproved}</p>
                                                    </div>
                                                </div>
                                                {isApproved == "accept" && localStorage.role == "academicHead" && currentChapter && currentChapter.chapterUnder == "line_art" &&
                                                    <div className="col-md-12">
                                                        <div className="form-group">
                                                            <label className="form-label">Reviewer Notes</label>
                                                            <textarea type="text" className="form-control" rows={4} placeholder="Reviewer Notes"
                                                                required value={revNotes} onChange={(e) => setRevNotes(e.target.value)} />
                                                            <p className="error">{errors.revNotes}</p>
                                                        </div>
                                                    </div>}
                                                {isApproved == "reject" && <div>
                                                    {currentChapter ?
                                                        <>
                                                            {!chapterContent.currentContent?.designGuidelines && <div className="col-lg-6 col-md-12">
                                                                <div className="form-group">
                                                                    <input type="button" onClick={() => setReviewModal(true)} class="btn btn-primary mr-2" value="Add Comment"></input>
                                                                </div>
                                                                {chapterComments && chapterComments.map((chapterComment, key) => {
                                                                    return <>
                                                                        <a href="javascript:void(0);" onClick={() => showCommentModal(chapterComment)}>Comment {key + 1}</a>
                                                                        <a href="javascript:void(0);" onClick={() => { setDeleteCommentId(chapterComment.id); setDeleteConfirmModal(true) }}><i className="fa fa-trash" style={{ marginLeft: '5px', color: '#db2828' }} aria-hidden="true"></i></a><br />
                                                                    </>
                                                                })}
                                                            </div>}
                                                        </>
                                                        :
                                                        <>
                                                            <div className="col-lg-6 col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">Add Review File</label>
                                                                    <input type="file" className="form-control"
                                                                        value={reviewValue} onChange={(e) => { setReviewFile(e.target.files[0]); setReviewValue(e.target.value) }} />
                                                                    <p className="error">{errors.chapterFile}</p>
                                                                </div>
                                                            </div>

                                                            <div className="col-lg-6 col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">Comment Label</label>
                                                                    <select class="form-control show-tick" required
                                                                        value={labelId} onChange={(e) => setLabelId(e.target.value)}>
                                                                        <option value="">Select Label</option>
                                                                        {labels && labels.map(label => {
                                                                            return <option value={label.id}>{label.labelName}</option>
                                                                        })}
                                                                    </select>
                                                                    <p className="error">{errors.labelId}</p>
                                                                </div>
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="form-group">
                                                                    <label className="form-label">Reviewer Notes</label>
                                                                    <textarea type="text" className="form-control" rows={4} placeholder="Reviewer Notes"
                                                                        required value={revNotes} onChange={(e) => setRevNotes(e.target.value)} />
                                                                    <p className="error">{errors.revNotes}</p>
                                                                </div>
                                                            </div>
                                                        </>}
                                                </div>}
                                                {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />}

                                                <div className="col-lg-12 mt-3">
                                                    <button type="submit" disabled={btnStatus || (isApproved == "reject" && revNotes.length < 1 && chapterComments.length < 1)} className="btn btn-primary">Submit</button>
                                                </div>
                                            </form>}
                                            {chapterContent.currentContent.canUploadDesignGuidelines == 1 && <form onSubmit={onDesignGuidelinesSubmit}>
                                                {/* <div className="col-lg-6 col-md-12" style={{ display: 'none' }}>
                                                <div className="form-group">
                                                    <label className="form-label">Add Design Guidlines</label>
                                                    <input type="file" className="form-control" required
                                                        value={dgValue} onChange={(e) => { setDgFile(e.target.files[0]); setDgValue(e.target.value) }} />
                                                    <p className="error">{errors.designGuidelines}</p>
                                                </div>
                                            </div> */}
                                                <div className="col-md-12">
                                                    <button type="button" class="btn btn-primary"
                                                        onClick={() => { setDesignGuidelinesModal(true); setIndex(-1); setDesignGuidelinesText("") }}><i class="fe fe-plus mr-2"></i>Add Design Guidline</button>
                                                    <div className="form-group">
                                                        {/* style={{width: '100%', overflowX: 'auto', whiteSpace: 'nowrap'}} */}
                                                        {/* {designGuidelinesArray?.map((dg, key) => {
                                                            return <button type="button" className="btn btn-warning m-1">Design Guideline {key + 1}</button>
                                                        })} */}

                                                        {/* <textarea type="text" className="form-control" rows={4} width="100%" placeholder="Add Design Guidlines"
                                                            value={designGuidelinesText} onChange={(e) => setDesignGuidelinesText(e.target.value)} /> */}
                                                        <p className="error">{errors.designGuidelinesText}</p>
                                                    </div>
                                                </div>
                                                {progress > 0 && <ProgressBar labelSize="10px" height="10px" bgcolor="#175759" completed={progress} />}
                                                {/* <div className="col-lg-12 mt-3">
                                                    <button type="submit" disabled={btnStatus} className="btn btn-primary">
                                                        {chapterContent.currentContent.designGuidelinesText ? 'Update' : 'Submit'}</button>
                                                </div> */}
                                            </form>}

                                            <div className="col-5 py-1"><strong>All Reviewers:</strong></div>
                                            <div className="col-7 py-1">
                                                {chapterContent && chapterContent.currentContent.totalReviewers.map(reviewer => {
                                                    return <img key={reviewer.id} className="avatar avatar-sm" src={server_url + reviewer.reviewerImage} data-toggle="tooltip" title={reviewer.reviewerName} data-original-title="Avatar Name" />
                                                })}
                                            </div>
                                            <div className="col-5 py-1"><strong>Pending Reviewers:</strong></div>
                                            <div className="col-7 py-1">
                                                {chapterContent && chapterContent.currentContent.pendingReviewers.map(reviewer => {
                                                    return <img key={reviewer.id} className="avatar avatar-sm" src={server_url + reviewer.reviewerImage} data-toggle="tooltip" title={reviewer.reviewerName} data-original-title="Avatar Name" />
                                                })}
                                            </div>
                                        </div>)}

                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.fullBookContent && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Full Book Content</h3>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <a onClick={() => downloadFile(base_url_file, chapterContent.fullBookContent.chapterFile)} href="javascript:void(0);" >View Full Book File</a>
                                        {currentChapter && currentChapter.hasExercise == 1 && chapterContent.fullBookContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.typeSettingContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}
                                        {chapterContent.typeSettingContent.designGuidelines == 1 && <a onClick={() => downloadFile(base_url_file, chapterContent.fullBookContent.designGuidelines)} href="javascript:void(0);" ><br />View Design Guidlines File</a>}
                                        {chapterContent.fullBookContent.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{chapterContent.fullBookContent.comments}</span></p>}
                                        <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.fullBookContent.created_at)}</p>
                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.drawingContent && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Drawing Content</h3>

                                    <div class="card-options ees">
                                        Chapter: {currentChapter && currentChapter.chapterName}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <a onClick={() => downloadFile(base_url_file, chapterContent.drawingContent.chapterFile)} href="javascript:void(0);" >View Drawing File</a>
                                        {currentChapter && currentChapter.hasExercise == 1 && chapterContent.drawingContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.typeSettingContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}
                                        {chapterContent.drawingContent.designGuidelines == 1 && <a onClick={() => downloadFile(base_url_file, chapterContent.drawingContent.designGuidelines)} href="javascript:void(0);" ><br />View Design Guidlines File</a>}
                                        {chapterContent.drawingContent.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{chapterContent.drawingContent.comments}</span></p>}
                                        <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.drawingContent.created_at)}</p>
                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.typeSettingContent && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Type Setting Content</h3>
                                    <div class="card-options ees">
                                        Chapter: {currentChapter && currentChapter.chapterName}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <a onClick={() => downloadFile(base_url_file, chapterContent.typeSettingContent.chapterFile)} href="javascript:void(0);" >View Type Setting File</a>
                                        {currentChapter && currentChapter.hasExercise == 1 && chapterContent.typeSettingContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.typeSettingContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}
                                        {chapterContent.typeSettingContent.designGuidelines == 1 && <a onClick={() => downloadFile(base_url_file, chapterContent.typeSettingContent.designGuidelines)} href="javascript:void(0);" ><br />View Design Guidlines File</a>}
                                        {chapterContent.typeSettingContent.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{chapterContent.typeSettingContent.comments}</span></p>}
                                        <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.typeSettingContent.created_at)}</p>
                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.lineArtContent && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Line Art Content</h3>
                                    <div class="card-options ees">
                                        Chapter: {currentChapter && currentChapter.chapterName}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <a onClick={() => downloadFile(base_url_file, chapterContent.lineArtContent.chapterFile)} href="javascript:void(0);" >View Line Art File</a>
                                        {currentChapter && currentChapter.hasExercise == 1 && chapterContent.lineArtContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.lineArtContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}
                                        {chapterContent.lineArtContent.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{chapterContent.lineArtContent.comments}</span></p>}
                                        <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.lineArtContent.created_at)}</p>
                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.writingContent && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Author Content</h3>
                                    <div class="card-options ees">
                                        Chapter: {currentChapter && currentChapter.chapterName}
                                    </div>
                                </div>
                                <div className="card-body">
                                    <div>
                                        <a onClick={() => downloadFile(base_url_file, chapterContent.writingContent.chapterFile)} href="javascript:void(0);" >View Content File</a>
                                        {currentChapter && currentChapter.hasExercise == 1 && chapterContent.writingContent.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, chapterContent.writingContent.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}
                                        {/* <a onClick={() => downloadFile(base_url_file, chapterContent.writingContent.designGuidelines)} href="javascript:void(0);" ><br />View Design Guidlines File</a> */}
                                        {chapterContent.writingContent.designGuidelines ? <p><strong>Design Guidelines: </strong><br />
                                            <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                onClick={() => {
                                                    startScroll > 0 && setEndScroll(endScroll - 1);
                                                    startScroll > 0 && setStartScroll(startScroll - 1)
                                                }}>
                                                <img src="/assets/images/left-solid.svg" width="30px" /></button>
                                            {JSON.parse(chapterContent?.writingContent?.designGuidelines)?.map((dg, key) => {
                                                return (key >= startScroll && key < endScroll) && <button type="button" className="btn btn-warning m-1"
                                                    onClick={() => { setDesignGuidelinesModal(true); setIndex(key); setShowFile(dg.file); setDesignGuidelinesText(dg.text) }}>Design Guideline {key + 1}</button>
                                            })}
                                            <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                onClick={() => {
                                                    endScroll < JSON.parse(chapterContent?.writingContent?.designGuidelines).length && setEndScroll(endScroll + 1);
                                                    endScroll < JSON.parse(chapterContent?.writingContent?.designGuidelines).length && setStartScroll(startScroll + 1)
                                                }}>
                                                <img src="/assets/images/right-solid.svg" width="30px" /></button>
                                        </p> : chapterContent.writingContent.designGuidelinesText && <p><strong>Design Guidelines: </strong> <br /> <span className="class-comments urdu-text">{chapterContent.writingContent.designGuidelinesText}</span></p>}

                                        {/* {chapterContent.writingContent.designGuidelinesText && <p><br /><strong>Design Guidlines:</strong> <br /> <span className="class-comments urdu-text">{chapterContent.writingContent.designGuidelinesText}</span></p>} */}
                                        {chapterContent.writingContent.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{chapterContent.writingContent.comments}</span></p>}
                                        <p><strong>Created At:</strong> {date_time_to_date_format(chapterContent.writingContent.created_at)}</p>
                                    </div>
                                </div>
                            </div>}

                            {chapterContent && chapterContent.contentHistory && chapterContent.contentHistory.length > 0 && <div className="card">
                                <div className="card-header">
                                    <h3 className="card-title">Logs ({chapterContent.logsCount})</h3>
                                    <div class="card-options ees">
                                        Chapter: {currentChapter && currentChapter.chapterName}
                                    </div>
                                </div>
                                <div className="card-body">
                                    {/* {reviewId = chapterContent.contentHistory[0].id} */}
                                    {chapterContent.contentHistory.map((content, key) => {
                                        return <div >
                                            {content.logCount && (<div className="card-header  text-center light-back">
                                                <h3 className="card-title">{content.logCount}</h3><div className="card-options ees">Chapter Phase: {content.chapterPhase}</div>

                                            </div>)}
                                            {localStorage.role == 'academicHead' && chapterContent.currentContent && chapterContent.currentContent.canReview == 1 && <input type="button" id={uuid()} name="check_box" value="&#9734;" className={"btn btn-primary btn-white notdisabled"} onClick={(e) => endoreReview(content.chapterId, content.reviewer, e)} />}
                                            <div className="timeline_item ">

                                                <img className="tl_avatar" src={server_url + content.reviewer.reviewerImage} title={content.reviewer.reviewerName} alt />
                                                <span><a href="javascript:void(0);">{content.reviewer.reviewerName} <span style={{ color: '#434a54' }}>({roles && roles[content.reviewer.role]})</span></a>  <small className="float-right text-right">{date_time_to_date_format(content.reviewer.created_at)}</small></span>
                                                {!content.designGuidelines && content.chapterComments.length > 0 ?
                                                    content.chapterComments.map((chapterComment, key) => {
                                                        return <>
                                                            <br /><a href="javascript:void(0);" onClick={() => showCommentModal(chapterComment)}>Comment {key + 1}</a>
                                                        </>
                                                    })
                                                    :
                                                    <h6 className="font600">Label:
                                                        {(content.reviewer.reviewStatus == 'accept' && content.reviewer.labelId == null) ? (<span style={{ 'color': '#175759' }}>Approved</span>) :
                                                            (<span style={{ 'color': content.reviewer.labelColor }}>{content.reviewer.labelName}</span>)}</h6>

                                                }
                                                <div className="log-border">
                                                    <span className="d-none">{reviewId = reviewId != content.id ? content.id : reviewId}</span>
                                                    {content.reviewer.reviewFile && <p><a onClick={() => downloadFile(base_url_file, content.reviewer.reviewFile)} href="javascript:void(0);" >View Reviewer File</a></p>}
                                                    {content.reviewer.comments && <div className="msg">
                                                        <p><span className="class-comments urdu-text">{content.reviewer.comments}</span></p>
                                                    </div>}

                                                    <a onClick={() => downloadFile(base_url_file, content.chapterFile)} href="javascript:void(0);" >View Content File</a>
                                                    <p><strong>Created At:</strong> {date_time_to_date_format(content.created_at)}</p>
                                                    {currentChapter && currentChapter.hasExercise == 1 && content.exerciseFile != null && <a onClick={() => downloadFile(base_url_file, content.exerciseFile)} href="javascript:void(0);" > <br /> View Exercise File</a>}

                                                    {content.designGuidelines && <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                        onClick={() => {
                                                            startScroll > 0 && setEndScroll(endScroll - 1);
                                                            startScroll > 0 && setStartScroll(startScroll - 1)
                                                        }}>
                                                        <img src="/assets/images/left-solid.svg" width="30px" /></button>}
                                                    {content.designGuidelines && JSON.parse(content.designGuidelines)?.map((dg, key) => {
                                                        return (key >= startScroll && key < endScroll) && <button type="button" className="btn btn-warning m-1"
                                                            onClick={() => { setDesignGuidelinesModal(true); setAdDG(false); setOriginalText(key + 1); setModalComments(content.chapterComments); setShowFile(dg.file); setIndex(key); setDesignGuidelinesText(dg.text) }}>Design Guideline {key + 1}
                                                        </button>
                                                    })}
                                                    {content.designGuidelines && <button type="button" className="btn" style={{ padding: '0px', margin: '0px' }}
                                                        onClick={() => {
                                                            endScroll < JSON.parse(content.designGuidelines).length && setEndScroll(endScroll + 1);
                                                            endScroll < JSON.parse(content.designGuidelines).length && setStartScroll(startScroll + 1)
                                                        }}>
                                                        <img src="/assets/images/right-solid.svg" width="30px" /></button>}
                                                    {/* <a onClick={() => downloadFile(base_url_file, content.designGuidelines)} href="javascript:void(0);" ><br />View Design Guidlines File</a>} */}
                                                    {content.comments && <p><br /><strong>Notes:</strong> <br /> <span className="class-comments">{content.comments}</span></p>}
                                                </div>
                                            </div>
                                        </div>
                                    })}
                                </div>
                            </div>}
                        </div>
                    </div>
                </div>

                <Modal
                    isOpen={modalIsOpen}
                    onAfterOpen={afterOpenModal}
                    onRequestClose={closeModal}
                    contentLabel="Example Modal"
                >
                    {/* http://docs.google.com/gview?url=http://example.com/path/to/your/document.doc&embedded=true */}
                    <button onClick={() => fileDownload()}><i class="fa fa-download" aria-hidden="true"></i></button>
                    <iframe id="fileUrlIframe" className="urdu-text" src={modalFileUrl} frameBorder="0"
                        style={{ border: '0', top: '0px', left: '0px', bottom: '0px', right: '0px', width: '100%', height: '100%' }}
                        allowFullScreen>
                    </iframe>
                </Modal>

                <Modal
                    isOpen={contentModal}
                    onAfterOpen={afterOpenContentModal}
                    onRequestClose={closeContentModal}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeContentModal}>close</button>
                    <textarea type="text" className="form-control urdu-text" rows={30} placeholder="Add Content"
                        value={chapterText} onChange={(e) => setChapterText(e.target.value)} />
                </Modal>

                <Modal
                    isOpen={reviewModal}
                    onAfterOpen={afterOpenreviewModal}
                    onRequestClose={closeReviewModal}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeReviewModal}>close</button>
                    <form onSubmit={addComment}>
                        <div className="form-group">
                            <label className="form-label">Original Content</label>
                            <textarea type="text" className="form-control urdu-text" rows={5} required
                                value={originalText} onChange={(e) => setOriginalText(e.target.value)} />
                            <p className="error">{errors.originalText}</p>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Suggestions</label>
                            <textarea type="text" className="form-control urdu-text" rows={5} required
                                value={suggestionText} onChange={(e) => setSuggestionText(e.target.value)} />
                            <p className="error">{errors.suggestionText}</p>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Comment Label</label>
                            <select class="form-control show-tick" required
                                value={labelId} onChange={(e) => setLabelId(e.target.value)}>
                                <option value="">Select Label</option>
                                {labels && labels.map(label => {
                                    return <option value={label.id}>{label.labelName}</option>
                                })}
                            </select>
                            <p className="error">{errors.labelId}</p>
                        </div>
                        <input type="submit" class="btn btn-primary mr-2" value="Submit"></input>
                    </form>
                </Modal>

                <Modal
                    isOpen={viewCommentModal}
                    onAfterOpen={afterOpenViewCommentModal}
                    onRequestClose={closeViewCommentModal}
                    contentLabel="Example Modal"
                >
                    <button onClick={closeViewCommentModal}>close</button>
                    <form>
                        <div className="form-group">
                            <label className="form-label">Original Content</label>
                            <textarea type="text" className="form-control urdu-text" rows={5} disabled
                                value={originalText} onChange={(e) => setOriginalText(e.target.value)} />
                            <p className="error">{errors.originalText}</p>
                        </div>

                        <div className="form-group">
                            <label className="form-label">Suggestions</label>
                            <textarea type="text" className="form-control urdu-text" rows={5} disabled
                                value={suggestionText} onChange={(e) => setSuggestionText(e.target.value)} />
                            <p className="error">{errors.suggestionText}</p>
                        </div>
                        <div className="form-group">
                            <label className="form-label">Comment Label</label>
                            <select class="form-control show-tick" disabled style={{ borderColor: labelColor, color: labelColor }}
                                value={labelId} onChange={(e) => setLabelId(e.target.value)}>
                                <option value="">Select Label</option>
                                {labels && labels.map(label => {
                                    return <option value={label.id}>{label.labelName}</option>
                                })}
                            </select>
                            <p className="error">{errors.labelId}</p>
                        </div>
                    </form>
                </Modal>

                <Modal
                    isOpen={deleteConfirmModal}
                    contentLabel="Example Modal"
                >
                    <p>Do You Want to Delete this Comment?</p>
                    <input type="button" class="btn btn-primary mr-2" onClick={() => setDeleteConfirmModal(false)} value="Cancel"></input>
                    <input type="button" class="btn btn-primary mr-2" onClick={() => deleteComment(deleteCommentId)} value="OK"></input>
                </Modal>
                {/* <Modal
                    isOpen={designGuidelinesModal}
                    contentLabel="Design Guidlines Modal"> */}
                <div className={designGuidelinesModal ? "modal fade show" : "modal fade"} tabIndex={-1} role="dialog" style={designGuidelinesModal ? { display: 'block', paddingRight: 0 } : { display: 'none' }} >
                    <div className="modal-dialog" role="document">
                        <div className="modal-content" style={{ overflowY: 'scroll', maxHeight: '600px' }}>
                            <div className="modal-header">
                                <h6 className="title" id="defaultModalLabel">Design Guideline</h6>
                            </div>
                            <form onSubmit={onDesignGuidelinesSubmit}>
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-12">
                                            <div className="form-group">
                                                {chapterContent?.currentContent?.canUploadDesignGuidelines > 0 ?
                                                    <textarea className="form-control" placeholder="Design Guideline" rows="10"
                                                        value={designGuidelinesText}
                                                        onChange={(e) => setDesignGuidelinesText(e.target.value)} /> : <p>
                                                        <span className="class-comments urdu-text">{designGuidelinesText}</span>
                                                    </p>}
                                                {showFile && <><a href="javascript:void(0);" onClick={() => { setShowImageModal(true) }}>View Content File</a></>}


                                                {localStorage.role == 'designer' && <>{addDG && <input type="file" className="form-control" required
                                                    value={chapterValue} onChange={(e) => { addChapterFile(e.target) }}
                                                />}
                                                </>
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {isApproved == 'accept' && <div className="modal-footer">
                                    {chapterContent?.currentContent?.canUploadDesignGuidelines > 0 && <>
                                        <button type="submit" className="btn btn-primary">{index == -1 ? "Add" : "Update"}</button>

                                        {(designGuidelinesArray.length > 1 && index != -1) && <button type="button" className="btn btn-danger"
                                            onClick={() => deleteDesignGuideline()}>Delete</button>}
                                    </>}
                                    <button type="button" className="btn btn-default" data-dismiss="modal"
                                        onClick={() => { setDesignGuidelinesModal(false); setAdDG(false) }}>Close</button>
                                </div>}
                            </form>
                            {isApproved === 'reject' &&
                                <div className="modal-body">
                                    <div className="row clearfix">
                                        <div className="col-12">

                                            <form onSubmit={addComment}>
                                                <div className="form-group">
                                                    <label className="form-label">Suggestions</label>
                                                    <textarea type="text" className="form-control urdu-text" rows={5} required
                                                        value={suggestionText} onChange={(e) => setSuggestionText(e.target.value)} />
                                                    <p className="error">{errors.suggestionText}</p>
                                                </div>
                                                <div className="form-group">
                                                    <label className="form-label">Comment Label</label>
                                                    <select class="form-control show-tick" required
                                                        value={labelId} onChange={(e) => setLabelId(e.target.value)}>
                                                        <option value="">Select Label</option>
                                                        {labels && labels.map(label => {
                                                            return <option value={label.id}>{label.labelName}</option>
                                                        })}
                                                    </select>
                                                    <p className="error">{errors.labelId}</p>
                                                </div>
                                                <input type="submit" class="btn btn-primary mr-2" value="Submit"
                                                    onClick={() => { setDesignGuidelinesModal(false); setAdDG(false) }}>
                                                </input><button type="button" className="btn btn-default" data-dismiss="modal"
                                                    onClick={() => { setDesignGuidelinesModal(false); setAdDG(false) }}>Close</button>
                                            </form>
                                        </div>
                                    </div>
                                </div>}

                            <div className="modal-body" >
                                <div className="row clearfix">
                                    <div className="col-12">
                                        {modalComments.map((commnt, key) => {
                                            return (commnt.originalText == (index + 1)) && <div className="timeline_item">

                                                <img className="tl_avatar" src={server_url + commnt?.reviewer?.reviewerImage} title={commnt?.reviewer?.reviewerName} alt />
                                                <span><a href="javascript:void(0);">{commnt?.reviewer?.reviewerName}
                                                    <span style={{ color: '#434a54' }}>({roles && roles[commnt?.reviewer?.role]})</span></a>
                                                    <small className="float-right text-right">{date_time_to_date_format(commnt?.reviewer?.created_at)}</small></span>

                                                <h6 class="font600">Label:<span style={{ color: commnt.labelColor }}>{commnt.labelName}</span></h6>
                                                <div className="log-border">
                                                    <div className="msg">
                                                        <p><span className="class-comments urdu-text">{commnt.suggestionText}</span></p>
                                                    </div>
                                                    {isApproved === 'reject' && <a href="javascript:void(0);" onClick={() => { deleteComment(commnt.id); setDesignGuidelinesModal(false); setAdDG(false) }}>Delete</a>}
                                                </div>
                                            </div>
                                        })}
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
                {/* </Modal> */}
                <div className={designGuidelinesModal ? "modal-backdrop fade show" : ""}></div>

                <div className={showImageModal ? "modal-backdrop fade show" : ""}></div>
                <div className={showImageModal ? "modal fade show" : "modal fade"} tabIndex={-1} role="dialog" style={showImageModal ? { display: 'block', paddingRight: 0 } : { display: 'none' }} >
                    <div className="modal-dialog" role="document" style={{ margin: '0px', width: '100%', maxWidth: '100%' }}>
                        <div className="modal-content">
                            <div className="modal-body">
                                <a href={`${server_url + showFile}?token=${localStorage.usertoken}`} target="_blank" >Download File</a>
                                <iframe className="iframe-100" style={{ width: '100%', height: '500px' }} width="100%" src={`${server_url + showFile}?token=${localStorage.usertoken}`} ></iframe>
                            </div>
                            <div className="modal-footer">
                                <button type="button" className="btn btn-default" data-dismiss="modal"
                                    onClick={() => { setShowImageModal(false); setFileURL(null) }}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ChapterDetail;
