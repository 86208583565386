import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useRouteMatch } from "react-router-dom";
import { getCategories } from "../../actions/categoryActions";
import { setProjectsFilter, setBooksCategory, getProjects } from "../../actions/projectActions";

const ProjectsHeader = () => {
    let match = useRouteMatch();
    const dispatch = useDispatch();
    const filterr = useSelector(state => state.books.filter);
    const categories = useSelector(state => state.categories.categories);
    const [filters, setFilters] = useState({});

    // const setFilter = (filter) => {
    //     dispatch(setProjectsFilter(filter));
    // }
    
    const setPhase = (bookPhase) => {
        setFilters({...filters, bookPhase})
    }
    const setCategory = (categoryId) => {
        setFilters({...filters, categoryId});
    }

    useEffect(() => {
        dispatch(getCategories());
        setFilters({});
    }, []);

    useEffect(() => {
        dispatch(getProjects({filters}));
    }, [filters]);

    return (
        <div className="section-body">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="d-flex justify-content-between align-items-center">
                            <ul className="nav nav-tabs page-header-tab">
                                <li className="nav-item">
                                    <Link className="nav-link active" data-toggle="tab" to={`${match.url}`}>Books</Link>
                                </li>
                                {/* <li className="nav-item"><a className="nav-link" data-toggle="tab" href="#Project-UpComing">UpComing</a></li> */}
                                {(localStorage.role == 'superAdmin' || localStorage.role == 'projectCoordinator') && <li className="nav-item">
                                    <Link className="nav-link" data-toggle="tab" to={`${match.url}/add-book`}>Add New Book</Link>
                                </li>}
                            </ul>
                            <div className="d-md-flex">
                                {/* <div className="input-group mr-2">
                                    <select className="btn-hallow mr-2" style={{ borderRadius: '0.25rem' }}
                                        onChange={(e) => {setCategory(e.target.value)}}>
                                        <option value="">All categories</option>
                                        {categories.map(category =>
                                            <option value={category.id}>{category.categoryName}</option>
                                        )}
                                    </select>
                                    
                                    <select className="btn-hallow mr-2" style={{ borderRadius: '0.25rem' }}
                                        onChange={(e) => {setPhase(e.target.value)}}>
                                        <option value="">All Phases</option>
                                        <option value="complete">Completed</option>
                                        <option value="writing">Writing</option>
                                        <option value="designing">Designing</option>
                                    </select>
                                    <input
                                        type="button" onClick={() => setFilter('all')}
                                        className={filterr == 'all' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                        value="All Books" />

                                    <input type="button" onClick={() => setFilter('complete')}
                                        className={filterr == 'complete' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                        value="Completed" />

                                    <input type="button" onClick={() => setFilter('writing')}
                                        className={filterr == 'writing' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                        value="Writing" />

                                    <input type="button" onClick={() => setFilter('designing')}
                                        className={filterr == 'designing' ? 'btn btn-hallow mr-2' : 'btn btn-primary mr-2'}
                                        value="Designing" /> 
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}

export default ProjectsHeader;